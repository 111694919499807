import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const isUser = Cookies.get("userToken") ? true : false;
const initialState = {
  userToken: [],
  userLogin: isUser,
  loginFormOpen: false,
};

export const userLogin = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    addUserLogin: (state, action) => {
      state.userToken = action.payload;
      state.userLogin = true;
    },
    userLogout: (state) => {
      state.userToken = [];

      state.userLogin = false;
    },
    openFormHandle: (state) => {
      state.loginFormOpen = true;
    },
    closeFormHandle: (state) => {
      state.loginFormOpen = false;
    },
  },
});

export const { addUserLogin, userLogout, openFormHandle, closeFormHandle } =
  userLogin.actions;
export default userLogin.reducer;
