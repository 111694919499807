import React from 'react';
import './DoctorCards.css';

import { BsArrowRightShort } from 'react-icons/bs';

// import doctorIcon1 from '../../assets/dashboardDoctorIcons/icon1.jpg';
import { useState } from 'react';
import PaginationComponent from '../../components/Pagination/PaginationComponent';

import { useContext } from 'react';
import DataContext from '../../../context api/StateProvider';

// import Modal from '../Modal';
import Button from '../Button';
import DoctorDetailsPage from '../../Pages/Doctors/DoctorDetailsPage';
import { Edit } from '@mui/icons-material';
import { Box, Modal, Typography } from '@mui/material';

export default function DoctorCards() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 550,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
  };
const [open,setOpen]=useState(false)
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
   const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };


  const { doctors, setSeeDoctorsInfo } = useContext(DataContext);
  //Table Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ////////////////////////////////////////

  const doctorData = doctors;

  // Doctor Information State

  // --------------------------------

  //Modal
  const [showModal, setShowModal] = useState(false);

  const handleClick = (value) => {
    setShowModal(true);
    setSeeDoctorsInfo(value);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const actionBar = (
    <div>
      <Button onClick={closeModal} primary>
        Close
      </Button>
    </div>
  );

  // const modal = (
  //   <Modal onClose={closeModal} actionBar={actionBar}>
  //     <DoctorDetailsPage />
  //   </Modal>
  // );

  // console.log(seeDoctorsInfo);
  const renderedDoctorData = doctorData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((data, index) => {
      return (
        <div
          onClick={() => handleClick(data)}
          key={index}
          className="admin-home-section-doctors-card flex w-[180px] cursor-pointer flex-row items-center gap-[5px] border-[1px] border-solid border-inherit p-[10px]"
        >
          <img
            className="h-[30px] w-[30px]"
            src={data.doctorPhoto}
            alt={`${index}`}
          />
          <div className="flex flex-col">
            <h3 className="text-[12px]">{`${data.doctorFirstName}-${data.doctorLastName}`}</h3>

            <p className="text-[12px]">{data.doctorDOB}</p>

            <p className="text-[12px]">{data.doctorGender.label}</p>
          </div>
          <BsArrowRightShort />
        </div>
      );
    });
  return (
    <div className="admin-section-doctors flex flex-col gap-[1rem]">
      <div className="flex flex-row justify-between">
        <h3 className="admin-heading-sm">All Doctors</h3>
        {/* <div className="flex cursor-pointer flex-row items-center">
          <h4>View All</h4>
          <BsArrowRightShort className="text-[2rem]" />
        </div> */}
      </div>
      <div className="admin-home-section-doctors-cards w-full gap-[1.5rem]">
        {renderedDoctorData}
      </div>
     
      <div className="adminorderpage_table_table">
        <table>
          <thead>
            <tr>
              <th>S/L</th>
              <th style={{ width: "250px" }}>Doctor Name</th>
              <th>Sex</th>
              <th>Main specialization</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

              <tr >
                <td>{ + 1}</td>
                <td style={{ width: "250px" }}>
                  <p>Category Name</p>
                </td>
                <td>{}</td>
                <td>
                </td>
                <td>
                  <Edit
                    style={{
                      color: "#6E798C",
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                    // onClick={() => editSubCategoryHandle(item?.id)}
                    onClick={() => handleOpen1()}
                  />
                
                   
                  
                </td>
              </tr>
    
          </tbody>
        </table>
      </div>
      
<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Sub Category
            </Typography>
            <p className="modal_form_para">Sub Category</p>
            <span>
              <input
                type="text"
                placeholder="Sub Category Name"
                // onChange={(e) => setSubCategoryName(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Category</p>
            <span>
              <select
                // onChange={(e) => setSelectedCategory(e.target.value)}
                required
              >
                <option>Select One Option</option>
              
              </select>
            </span>

            <button className="modal_form_buttom">Add Sub Category</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Edit Sub Category
            </Typography>
            <p className="modal_form_para">Sub Category</p>
            <span>
              <input
                type="text"
                placeholder="Sub Category Name"
                // value={subCategoryName}
                // onChange={(e) => setSubCategoryName(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Category</p>
            <span>
              <select
               
                required
              >
                <option>Select One Option</option>
              
              </select>
            </span>

            <button className="modal_form_buttom">Add Sub Category</button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
