import React, { useEffect } from "react";
import "./Product1_Card.css";
import img1 from "../img/image 4.jpg";
import { Share } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { cartAllData } from "../Slice/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { openFormHandle } from "../Slice/userLogin";
function Product1_Card({
  id,
  title,
  image,
  quantity,
  productUnit,
  mrp,
  price,
}) {
  const history = useNavigate();
  const notify = () => toast.success("Successfully Add To Cart!");
  // const [goToCart, setGoToCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userId = Cookies.get("userId");
  const { cart } = useSelector((state) => state.cartData);
  const { userLogin } = useSelector((state) => state.userlogin);
  const [isProduct, setIsProduct] = useState(false);
  const addToCard = async () => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("product_id", id);
    formData.append("number_of_item", 1);
    formData.append("per_item_price", price);
    formData.append("total_price", price);
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "Create-carts"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data) {
      notify();
      dispatch(cartAllData());
    }
  };
  useEffect(() => {
    cart?.find((item) => item?.product_id === id)
      ? setIsProduct(true)
      : setIsProduct(false);
  }, [cart, id, userId]);
  return (
    <div className="product1Card" style={{ cursor: "pointer" }}>
      <div
        className="product1Card_img"
        onClick={() => history(`/singlepage/${id}`)}
      >
        <img
          src={image ? process.env.React_App_Base_Image_Url + image : img1}
          alt="product logo"
        />
      </div>
      <div className="product1Card_details">
        <div className="product1Card_details_details">
          <h6>{title}</h6>
        </div>
        {/* <div className="product1Card_details_description">
          <p>Premium pepperoni and cheese is made with real mozzarella</p>
        </div> */}
        <div className="product1Card_details_price">
          <strong>₹{price}</strong>
          <s style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "400" }}>
            ₹{mrp}
          </s>
        </div>
        <div
          className="product1Card_details_price"
          style={{ color: "lightgray" }}
        >
          <p>{quantity}</p>
          <p style={{ marginLeft: "5px" }}>{productUnit}</p>
        </div>

        <div className="product1Card_details_button">
          {userLogin ? (
            isProduct ? (
              <button
                className="product1Card_details_button_button"
                onClick={() => history("/cart")}
                style={{ cursor: "pointer" }}
              >
                Go To Cart
              </button>
            ) : isLoading ? (
              <button
                className="product1Card_details_button_button"
                onClick={addToCard}
                style={{ cursor: "pointer" }}
              >
                Adding...
              </button>
            ) : (
              <button
                className="product1Card_details_button_button"
                onClick={addToCard}
                style={{ cursor: "pointer" }}
              >
                Add To Cart
              </button>
            )
          ) : (
            <button
              className="product1Card_details_button_button"
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(openFormHandle())}
            >
              Add To Cart
            </button>
          )}

          <span>
            <Share />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Product1_Card;
