import React from 'react'
import './FeatureCard.css'
import { useNavigate } from 'react-router-dom'
function FeatureCard({image,link}) {
  const history=useNavigate()
  return (
    <div className='featureCard' onClick={()=>history(`/brand/brand/${link}`)}>
        <div className='featureCard_img'>
            <img src={process.env.React_App_Base_Image_Url+image} alt='feature card'/>
        </div>
        {/* <div className='featureCard_title'>
            Title
        </div> */}
    </div>
  )
}

export default FeatureCard