import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "./Pagination.css";

export default function BasicPagination({ count, setPage }) {
  return (
    <Stack spacing={2}>
      <Pagination
        count={count}
        color="primary"
        shape="rounded"
        onChange={(_, value) => {
          setPage(value);
          window.scroll(0, 0);
        }}
      />
    </Stack>
  );
}
