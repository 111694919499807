import React, { useEffect, useRef, useState } from "react";
import "./SinglePage.css";

import { Chat, LocalMall, LocalShipping, Star } from "@mui/icons-material";

import RatingCard from "../../Rating Card/RatingCard";
import ReactImageMagnify from "react-image-magnify";
import Quantity from "../../Quantity/Quantity";
// import { fontWeight } from "@mui/system";

import { useNavigate, useParams } from "react-router-dom";
import SubNav from "../SubNav/SubNav";
import Navbar from "../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import axios from "axios";
import Cookies from "js-cookie";
import { cartAllData } from "../../Slice/cartSlice";
import { Backdrop, Box, CircularProgress, Fade, Modal } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { openFormHandle } from "../../Slice/userLogin";
import Product_Card from "../../Product Card/Product_Card";

function SinglePage() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
    outline: "transparent",
    borderRadius: "5px",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const notify = () => toast.success("Successfully Add To Cart!");
  const notify1 = () => toast.warning("Something Went Wrong!");
  const notify2 = () => toast.success("Successfully Added Review!");
  const [selectedImage, setSelectedImage] = useState();

  const { Product } = useSelector((state) => state.Product);
  const [productData, setProductData] = useState();
  const [categoryId, setCategoryId] = useState();
  const { Id } = useParams();
  const filterProduct = () => {
    const filter = Product?.product?.filter((item) => item.id === Number(Id));
    // console.log(filter,'filter')
    setProductData(filter && filter[0]);
  };
  useEffect(() => {
    filterProduct();
  }, [Product, Id]);
  useEffect(() => {
    setCategoryId(productData && productData?.category_id);
  }, [productData]);
  useEffect(() => {
    console.log(categoryId, "categoryId");
  }, [categoryId]);
  const [amount, setAmount] = useState(1);
  const handleDecrese = () => {
    amount > 1 ? setAmount(amount - 1) : setAmount(1);
  };
  const handleIncrease = () => {
    setAmount(amount + 1);
  };

  const refs = useRef([]);
  refs.current = [];
  const addRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };


  const { cart, loading } = useSelector((state) => state.cartData);
  const { userLogin } = useSelector((state) => state.userlogin);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [similarProduct, setSimilarProduct] = useState();
  const [isProduct, setIsProduct] = useState(false);

  const selectedImagehandle = (e) => {
    setSelectedImage(e.target.src);
  };
  useEffect(() => {
    setSelectedImage(
      process.env.React_App_Base_Image_Url + productData?.images[0]?.image_path
    );
  }, [productData]);
  const [isLoading, setIsLoading] = useState();
  const userId = Cookies.get("userId");
  const userName = Cookies.get("userName");
  const [allReviews, setAllReviews] = useState();
  // console.log(userId, "userId");
  const addToCartHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("product_id", productData?.id);
    formData.append("number_of_item", amount);
    formData.append("per_item_price", productData?.price);
    formData.append("total_price", productData?.price);
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "Create-carts"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data) {
      notify();
      dispatch(cartAllData());
    }
    if (!data) {
      notify1();
    }

    // userAllCart();
    console.log(data);
  };

  // useEffect(() => {
  //   userAllCart();
  // }, []);
  useEffect(() => {
    cart?.find((item) => item?.product_id === Id)
      ? setIsProduct(true)
      : setIsProduct(false);
  }, [cart, userId]);
  const simalarProduct = () => {
    const filter = Product?.product?.filter(
      (item) => item?.category_id === categoryId
    );
    setSimilarProduct(filter && filter);
  };
  useEffect(() => {
    simalarProduct();
  }, [categoryId]);

  const [userMessage, setUserMessage] = useState();
  const [isLoadingReview, setIsLoadingReview] = useState(false);
  const addReviewHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("published", 0);
    formData.append("name", userName);
    formData.append("product_id", Number(Id));
    formData.append("message", userMessage);
    const data = await axios
      .post(
        `${process.env.React_App_Base_Url + "create-all-reviews"}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoadingReview(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoadingReview(false));
    if (data) {
      notify2();
    }
    if (!data) {
      notify1();
    }
    handleClose();
    console.log(data);
  };
  const getReviewHandleByProductId = async () => {
    const  {data}  = await axios
      .post(
        `${process.env.React_App_Base_Url + "Get-reviewsbyproduct/" + Id}`,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllReviews(data && data?.data);

    console.log(data, "getReviewHandleByProductId");
  };
  useEffect(() => {
    getReviewHandleByProductId();
  }, []);
  useEffect(() => {
    console.log(selectedImage);
  }, [selectedImage]);
  return (
    <>
      <SubNav />
      <Navbar />
      <div className="singlePage">
        <div className="singlePage_product">
          <div className="singlePage_product_left">
            <div className="singlePage_product_left_small_imgs">
              <div className="singlePage_product_left_small_imgs_img">
                {productData?.images?.map((item, index) => (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      marginBottom: "10px",
                    }}
                    className={
                      `${
                        process.env.React_App_Base_Image_Url + item?.image_path
                      }` === selectedImage
                        ? "selected_image"
                        : ""
                    }
                    // className={i == 0 ? "img_wrap active" : "img_wrap"}
                    key={index}
                    onClick={selectedImagehandle}
                    ref={addRefs}
                  >
                    <img
                      src={
                        process.env.React_App_Base_Image_Url + item?.image_path
                      }
                      style={{
                        objectFit: "contain",
                        width: "95%",
                        height: "95%",
                      }}
                      alt="sdsdf"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="singlePage_product_left_big_img">
              {/* <img src={med1} /> */}
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "caspian",
                    isFluidWidth: true,
                    src: selectedImage,
                  },
                  largeImage: {
                    src: selectedImage,
                    width: 800,
                    height: 1000,
                  },
                  enlargedImageContainerDimensions: {
                    width: "100%",
                    height: "100%",
                    // enlargedImagePosition: "over",
                  },
                  lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                }}
              />
            </div>
          </div>

          <div className="singlePage_product_right">
            <div className="singlePage_product_right_left">
              <h6> {productData?.productName}</h6>
              <div className="singlePage_product_middle_review">
                <p>4.9</p>
                <span>
                  <Star className="singlePage_product_middle_review_star" />
                  <Star className="singlePage_product_middle_review_star" />
                  <Star className="singlePage_product_middle_review_star" />
                  <Star className="singlePage_product_middle_review_star" />
                </span>
                <p>
                  <Chat className="singlePage_product_middle_review_icon" /> 67
                  review
                </p>
              </div>
              <div className="singlePage_product_right_left_packs">
                <h6>Pack Size</h6>
                <div className="singlePage_product_right_left_packs_option">
                  {/* <div
                    onClick={quantityHandle}
                    className={
                      !quantity
                        ? "singlePage_product_right_left_packs_option_left quantity_select"
                        : "singlePage_product_right_left_packs_option_left"
                    }
                  >
                    <p>10 capsules</p>
                    <p>2800</p>
                  </div>
                  <div
                    onClick={quantityHandle}
                    className={
                      quantity
                        ? "singlePage_product_right_left_packs_option_right quantity_select"
                        : "singlePage_product_right_left_packs_option_right"
                    }
                  >
                    <p>20 capsules</p>
                    <p>3500</p>
                  </div> */}
                  <p style={{ fontSize: "18px", fontWeight: "400" }}>
                    {productData?.qualntity} {productData?.productUnit}
                  </p>
                </div>
              </div>
              <div className="singlePage_product_right_left_discription">
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Product Highlights
                </h6>
                <p>{productData?.shortDescription}</p>
              </div>
            </div>
            <div className="singlePage_product_right_right">
              <div className="singlePage_product_right_right_checkout">
                <p style={{ fontSize: "25px", fontWeight: "500" }}>
                  <strong className="singlepage_price">
                    ₹{productData?.price}
                  </strong>{" "}
                  <s
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      marginLeft: "10px",
                    }}
                  >
                    ₹{productData?.mrp}
                  </s>
                </p>
                <p className="singlePage_product_right_right_checkout_discount">
                  50%
                </p>
                <p style={{ fontSize: "14px", fontWeight: "400" }}>
                  Inclusive of all taxes
                </p>
                <span>
                  <Quantity
                    amount={amount}
                    handleDecrese={handleDecrese}
                    handleIncrease={handleIncrease}
                  />
                  <p>of 60 capsules</p>
                </span>
                {userLogin ? (
                  isProduct ? (
                    <button
                      className="singlepageaddbutton"
                      onClick={() => history("/cart")}
                    >
                      Go To Cart
                    </button>
                  ) : (
                    <button
                      className="singlepageaddbutton"
                      onClick={addToCartHandle}
                    >
                      Add To Card
                    </button>
                  )
                ) : (
                  <button
                    className="singlepageaddbutton"
                    onClick={() => dispatch(openFormHandle())}
                  >
                    Add To Card
                  </button>
                )}
              </div>
              <div className="singlePage_product_right_right_delivery">
                <p>
                  Earliest delivery by{" "}
                  <span className="singlepage_delivery">10pm, Tomorrow</span>
                </p>
                <p>
                  <span>Delivering to :</span>{" "}
                  <span
                    className="singlepage_delivery_arrow"
                    style={{ color: "#6E798C" }}
                  >
                    225001, bbk lucknow{" "}
                  </span>{" "}
                </p>
              </div>
              <div className="singlePage_product_right_right_policy">
                <div className="singlePage_product_right_right_policy_freeDelivery">
                  <div className="singlePage_product_right_right_policy_freeDelivery_left">
                    <LocalShipping style={{ color: "#D75951" }} />
                  </div>
                  <div className="singlePage_product_right_right_policy_freeDelivery_right">
                    <h6 style={{ fontSize: "17px", fontWeight: "500" }}>
                      Free Delivery
                    </h6>
                    <p style={{ fontSize: "14px", fontWeight: "400" }}>
                      Enter your Postal code for Delivery Availability
                    </p>
                  </div>
                </div>
                <div className="singlePage_product_right_right_policy_policy">
                  <div className="singlePage_product_right_right_policy_policy_left">
                    <LocalMall style={{ color: "#D75951" }} />
                  </div>
                  <div className="singlePage_product_right_right_policy_policy_right">
                    <h6 style={{ fontSize: "17px", fontWeight: "500" }}>
                      Return Delivery
                    </h6>
                    <p style={{ fontSize: "14px", fontWeight: "400" }}>
                      Free 30 days Delivery Return. Details
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="singlePage_discrption">
          <div className="singlePage_discrption_left">
            {HTMLReactParser(productData ? productData?.description : "")}
          </div>
          {/* <div className="singlePage_discrption_right">
            <img src={ads} />
            <div className="singlePage_discrption_right_text">
              <p>Ads</p>
            </div>
          </div> */}
        </div>
        <div className="singlePage_rating">
          <div className="singlePage_rating_heading">
            <h6>Ratings & Reviews</h6>
            {userLogin ? (
              <button className="button" onClick={handleOpen}>
                Add Review
              </button>
            ) : (
              <button
                className="button"
                onClick={() => dispatch(openFormHandle())}
              >
                Add Review
              </button>
            )}
          </div>

          {allReviews?.filter((item) => item?.published === 1)?.length > 0 ? (
            allReviews
              ?.filter((item) => item?.published === 1)
              ?.map((item, index) => (
                <RatingCard name={item?.name} message={item?.message} />
              ))
          ) : (
            <div className="flex w-full items-center justify-center">
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                No Review Found
              </p>
            </div>
          )}
        </div>
        <div className="singlePage_similarProduct">
          <div className="singlePage_similarProduct_heading">
            <h6>Similar products</h6>
          </div>
          <div className="singlePage_similarProduct_product">
            {similarProduct?.slice(0, 4)?.map((item) => (
              <Product_Card
                title={item?.productName}
                img={item?.images[0]?.image_path}
                quantity={item?.qualntity}
                productUnit={item?.productUnit}
                product_id={item?.id}
                price={item?.price}
                mrp={item?.mrp}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
      {loading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style1}>
            <form className="review_modal" onSubmit={addReviewHandle}>
              <input type="text" placeholder="Name" value={userName} disabled />
              <textarea
                rows={5}
                resize="none"
                placeholder="Message"
                onChange={(e) => setUserMessage(e.target.value)}
              />
              {/* <Rating name="half-rating" defaultValue={4.5} precision={0.5} /> */}
              {isLoadingReview ? (
                <button className="button" disabled style={{ cursor: "none" }}>
                  Adding... Review
                </button>
              ) : (
                <button className="button">Submit</button>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default SinglePage;
