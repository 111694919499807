import React, { useEffect, useState } from "react";
import "./DoctorCheckout.css";
import img from "../../../img/image 34.jpg";
import SubNav from "../../SubNav/SubNav";
import Navbar from "../../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import { Chat, VideoChat } from "@mui/icons-material";
import { addSelectedDoctor } from "../../../Slice/doctorSlice";
import { openFormHandle } from "../../../Slice/userLogin";
function DoctorCheckout() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const { Id } = useParams();
  const [doctor, setDoctor] = useState();
  const { doctorData } = useSelector((state) => state.doctor);
  const { userLogin } = useSelector((state) => state.userlogin);
  const [userDeatils, setUserDetails] = useState();
  console.log(userLogin, "userLogin");

  const userId = Cookies.get("userId");
  const findDoctorHandle = () => {
    const filter = doctorData?.doctors?.find((item) => item?.id === Number(Id));
    setDoctor(filter && filter);
    console.log(filter && filter);
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userDetailsHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "get-one-user/" + userId}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    console.log(data, "userid");
    setUserDetails(data && data?.user);
  };
  useEffect(() => {
    userDetailsHandle();
  }, [userId]);
  useEffect(() => {
    findDoctorHandle();
  }, [doctorData]);
  const [videoCall, setVideoCall] = useState(false);
  const [selected, setSelected] = useState("");
  const history = useNavigate();
  if (userLogin === false) {
    return (
      <div>
        <SubNav />
        <Navbar />
        <div className="w-full h-full flex items-center justify-center flex-col" style={{height:'80vh'}}>
          <p style={{fontSize:'22px',fontWeight:'500'}} className="mb-2">You Must To Be Login</p>
          <button className="button" onClick={() => dispatch(openFormHandle())}>
            Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="doctorcheckout">
      <SubNav />
      <Navbar />
      <div className="doctorcheckout_heading">
        <h6>CHECKOUT</h6>
      </div>
      <div className="doctorcheckout_cart">
        <div className="doctorcheckout_cart_left">
          <div className="doctorcheckout_cart_left_profile">
            <img
              src={
                doctor &&
                process.env.React_App_Base_Image_Url + doctor?.profile_image
              }
            />
            <div className="doctorcheckout_cart_left_profile_details">
              <h6>
                Dr. {doctor?.first_name} {doctor?.last_name}
              </h6>
              <p>
                {doctor?.main_specialization} |{" "}
                {doctor?.secondary_specialization} | {doctor?.experience} YRS.
                EXP
              </p>
            </div>
          </div>
        </div>
        <div className="doctorcheckout_cart_right">
          <h6>PATIENT DETAILS</h6>
          <div className="doctorcheckout_cart_right_top">
            <span>
              <p>Patient Name</p>
              <p>{userDeatils?.name}</p>
            </span>
            <span>
              <p>Patient Number</p>
              <p>{userDeatils?.mobile_number}</p>
            </span>
            <p>*Please select the patient before proceeding to pay!</p>
            <p>
              {" "}
              *Make sure to add valid patient details, it will be reflected on
              Prescription and Invoice.
            </p>
          </div>
          <div className="doctorcheckout_cart_right_bottom" >
            <span
              className={
                selected === "Chat"
                  ? "doctorcheckout_cart_right_bottom_icon_active"
                  : "doctorcheckout_cart_right_bottom_icon"
              }
              onClick={(e) => setSelected("Chat")}
            >
              <Chat />
              <p>Chat</p>
            </span>
          </div>
          {selected === "Chat" && (
            <div className="doctorcheckout_cart_right_down">
              <h6>TOTAL CHARGES</h6>
              <div>
                <span className="doctorcheckout_cart_right_down_span_top">
                  <p>Sub Total</p>
                  <strong>₹1000</strong>
                </span>
              </div>
              <div>
                <span className="doctorcheckout_cart_right_down_span_down">
                  <p>To Pay</p>
                  <strong>₹1100</strong>
                </span>
              </div>
              <div className="doctorcheckout_cart_right_down_policy">
                <p>
                  One-time Hospital Registration Charges might be applicable for
                  new users.
                </p>
              </div>
              <div className="doctorcheckout_cart_right_down_paybutton">
                <div className="chat_procees">
                  <button
                    onClick={() => [
                      history(`/chatwithdoctor/${doctor.id}`),
                      dispatch(addSelectedDoctor(doctor)),
                    ]}
                  >
                    Proceed To Chat
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* {selected==='Chat' && <div className="chat_procees">
            <button onClick={()=>[history(`/chatwithdoctor/${doctor.id}`),dispatch(addSelectedDoctor(doctor))]}>Proceed To Chat</button>
          </div>} */}
        </div>
      </div>
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default DoctorCheckout;
