import React from "react";
import "./TrendingPage.css";
import Categories from "../../Categories/Categories";
// import Product1_Card from "../../Product Card/Product1_Card";
import { HighlightOff, Search, Sort, Tune } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import LargestHealthCard from "../../LargestHealthCard/LargestHealthCard";
import { Link, useParams } from "react-router-dom";
import Banner1 from "../../Banner/Banner1";
import BasicPagination from "../../Pagination/Pagination";
import Navbar from "../Navbar/Navbar";
import SubNav from "../SubNav/SubNav";
import Footer from "../../Footer/Footer";
import { useSelector } from "react-redux";
import Product_Card from "../../Product Card/Product_Card";
import bannerImg from "../../img/Rectangle 19.jpg";

function TrendingPage() {
  const [categoryBanner, setCategoryBanner] = useState();
  const [allProduct, setAllProduct] = useState();
  const { Category, SubCategory, brand, brandId, AllMedicine } = useParams();
  const { categoryData } = useSelector((state) => state.category);
  const { banner } = useSelector((state) => state.banner);
  const { Product } = useSelector((state) => state.Product);
  // const [activeState, setActiveState] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const getBanner = () => {
    const filter = banner?.homepage?.filter(
      (item) => item?.category_id === Number(Category)
    );
    setCategoryBanner(
      filter?.length > 0
        ? filter[0]?.image
        : banner?.homepage?.length > 0
        ? banner?.homepage[0]?.image
        : bannerImg
    );
  };
  const getCategoryProductHandle = () => {
    const filter = Product?.product?.filter(
      (item) => item?.category_id === Number(Category)
    );
    setAllProduct(filter && filter);
    console.log(filter, "getCategoryProductHandle");
  };
  const getSubCategoryProductHandle = () => {
    const filter = Product?.product?.filter(
      (item) => item?.sub_category_id === Number(SubCategory)
    );
    setAllProduct(filter && filter);
    console.log(filter, "sub_category_id");
  };
  const getBrandHandle = () => {
    setAllProduct(Product && Product?.product);
    console.log("brand");
  };
  const getProductWithBrandId = () => {
    const filter = Product?.product?.filter(
      (item) => item?.brand_id === Number(brandId)
    );
    setAllProduct(filter && filter);
    console.log(filter, "sub_category_id");
  };

  const getCategory = () => {
    if (SubCategory === undefined && brand === undefined) {
      console.log(
        categoryData?.data?.find((item) => item?.id === 6),
        "hvhh"
      );
    }
  };
  const getAllMedicine = () => {
    setAllProduct(Product && Product?.product);
  };
  useEffect(() => {
    if (
      SubCategory === undefined &&
      brand === undefined &&
      AllMedicine === undefined
    ) {
      getCategoryProductHandle();
    }
    if (SubCategory !== undefined) {
      getSubCategoryProductHandle();
      console.log("hello from inside");
    }
    if (
      brand !== undefined &&
      Category === undefined &&
      SubCategory === undefined &&
      AllMedicine === undefined
    ) {
      getBrandHandle();
      console.log("hello from getBrandHandle");
    }
    if (brandId !== undefined) {
      getProductWithBrandId();
      console.log("hello from getProductWithBrandId");
    }
    if (
      AllMedicine !== undefined &&
      Category === undefined &&
      SubCategory === undefined &&
      brand === undefined &&
      brandId === undefined
    ) {
      getAllMedicine();
      console.log("hello from getAllMedicine");
    }

    getCategory();

    console.log(Category, SubCategory?.length, brand, banner);
  }, [Category, brand, Product, brandId, AllMedicine, banner]);
  useEffect(() => {
    getBanner();
  }, [banner]);

  const [sort, setSort] = useState(false);
  const [filter, setFilter] = useState(false);
  const [brands, setBrands] = useState(true);
  const [product, setProduct] = useState(false);
  const filterfilterHandle = (e) => {
    console.log(e.target.textContent);
    if (e.target.textContent === "Brands") {
      setBrands(true);
      setProduct(false);
    }
    if (e.target.textContent === "Discount") {
      setBrands(false);
      setProduct(true);
    }
  };
  const sortHandle = () => {
    setSort(!sort);
  };
  const filterHandle = () => {
    setFilter(!filter);
  };
  useEffect(() => {
    if (sort === true) {
      setFilter(false);
      console.log(filter, "filter");
    }
    if (filter === true) {
      setSort(false);
      console.log(sort, "sort");
    }
  }, [sort, filter]);
  useEffect(() => {
    setCount(Math.ceil(allProduct?.length / 10).toFixed(0));
  }, [allProduct]);
  useEffect(() => {
    console.log(categoryBanner, "categoryBanner");
    // console.log( banner?.homepage?[0], "categoryBanner");
  }, [categoryBanner]);
  return (
    <>
      <SubNav />
      <Navbar />
      <div className="trendingpage">
        <div className="trendingpage_filter">
          <div className="trendingpage_filter_top">
            <div className="trendingpage_filter_left">
              <span onClick={sortHandle}>
                <Sort />
                <p>Sort by Relevance</p>
              </span>
            </div>
            <div className="trendingpage_filter_right">
              <span onClick={filterHandle}>
                <Tune />
                <p>Filters</p>
              </span>
            </div>
          </div>
        </div>
        {sort && (
          <div className="sortMenu">
            <div className="sortMenu_heading">
              <p>Sort by</p>
              <HighlightOff
                onClick={() => setSort(false)}
                className="sortMenu_heading_close"
              />
            </div>
            <div className="sortMenu_option">
              <span>
                <input type="radio" />
                <p>Relevance</p>
              </span>{" "}
              <span>
                <input type="radio" />
                <p>Avarage Customer Rating</p>
              </span>{" "}
              <span>
                <input type="radio" />
                <p>Price Low To High</p>
              </span>{" "}
              <span>
                <input type="radio" />
                <p>Price High To Low </p>
              </span>{" "}
              <span>
                <input type="radio" />
                <p>Discount</p>
              </span>
            </div>
          </div>
        )}
        {filter && (
          <div className="sortMenu">
            <div className="sortMenu_heading">
              <p>Filter by</p>
              <HighlightOff
                onClick={() => setFilter(false)}
                className="sortMenu_heading_close"
              />
            </div>
            <div className="filterMenu_option">
              <div className="filterMenu_option_left">
                <p onClick={filterfilterHandle}>Brands</p>
                <p onClick={filterfilterHandle}>Discount</p>
                <p>Product Form</p>
                <p>User</p>
              </div>
              <div className="filterMenu_option_right">
                {brands && (
                  <div className="filterMenu_option_right_brands">
                    <div style={{ height: "40px" }}>
                      <input type="text" placeholder="Brands" />
                      <Search />
                    </div>
                    <div className="filterMenu_option_right_brands_brand">
                      <div>
                        <input type="checkbox" />
                        <span>
                          <p>Brands</p>
                          <p>12</p>
                        </span>
                      </div>
                      <div>
                        <input type="checkbox" />
                        <span>
                          <p>Brands</p>
                          <p>12</p>
                        </span>
                      </div>
                      <div>
                        <input type="checkbox" />
                        <span>
                          <p>Brands</p>
                          <p>12</p>
                        </span>
                      </div>
                      <div>
                        <input type="checkbox" />
                        <span>
                          <p>Brands</p>
                          <p>12</p>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="trendingpage_top">
          <div className="trendingpage_left">
            <Categories />
          </div>
          <div className="trendingpage_right">
            <div className="trendingpage_right_title">
              {" "}
              <h6 style={{ color: "#2A7FBA" }}>
                <Link to="/" style={{ color: "grey", textDecoration: "none" }}>
                  Home
                </Link>{" "}
                / Products
              </h6>
            </div>
            <Banner1
              img={process.env.React_App_Base_Image_Url + categoryBanner}
            />
            <div className="trendingpage_right_cards">
              {allProduct?.length > 0 ? (
                allProduct
                  ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                  ?.map((item) => (
                    <Product_Card
                      title={item?.productName}
                      img={item?.images[0]?.image_path}
                      quantity={item?.qualntity}
                      productUnit={item?.productUnit}
                      product_id={item?.id}
                      price={item?.price}
                      mrp={item?.mrp}
                    />
                  ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    marginTop: "50px",
                  }}
                >
                  <p style={{ fontSize: "22px", fontWeight: "500" }}>
                    No Product Found
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <BasicPagination count={count > 0 ? count : 1} setPage={setPage} />
        </div>

        <LargestHealthCard />
      </div>

      <Footer />
    </>
  );
}

export default TrendingPage;
