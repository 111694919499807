import {
  AccountBalance,
  CalendarMonth,
  FilterAlt,
  LocationOn,
  Medication,
  Report,
  Search,
  Sort,
} from "@mui/icons-material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../../Button/Button";
import img from "../../../img/Rectangle 176.jpg";
import DoctorCard from "../Doctor Card/DoctorCard";
import DoctorSpecialistCard from "../Doctor Card/DoctorSpecialistCard";
import "./AllDoctor.css";
import { useState } from "react";
import img1 from "../../../img/Rectangle 94.jpg";
import { Box, Modal, Typography } from "@mui/material";
// import Calendar from 'react-calendar';
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import SubNav from "../../SubNav/SubNav";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../../Footer/Footer";
import { useSelector } from "react-redux";

function AllDoctor() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [search, setSearch] = useState(false);
  const [value, onChange] = useState(new Date());

  console.log(value);
  const searchHandle = () => {
    setSearch(true);
  };
  const [searchData, setSearchData] = useState("");
  const getSearchData = (event) => {
    console.log(event.target.value);
  };
  const [allDoctors, setAllDoctors] = useState();
  const { doctorData } = useSelector((state) => state.doctor);
  const [location, setLocation] = useState();
  const [searchDoctor, setSearchDoctor] = useState();
  useEffect(() => {
    setAllDoctors(doctorData?.doctors && doctorData?.doctors);
  }, [doctorData]);
  const handleDoctorSearch = () => {
    if (location?.length === 0 && searchDoctor?.length > 0) {
      console.log(" doctor");
      const filter = doctorData?.doctors?.filter(
        (item) =>
          item?.main_specialization
            ?.toLowerCase()
            ?.includes(searchDoctor?.toLowerCase()) ||
          item?.secondary_specialization
            ?.toLowerCase()
            ?.includes(searchDoctor?.toLowerCase())
      );
      console.log(filter);
      setAllDoctors(filter && filter);
    }
    if (location?.length > 0 && searchDoctor?.length === 0) {
      const filter = doctorData?.doctors?.filter(
        (item) =>
          item?.city?.toLowerCase()?.includes(location?.toLowerCase()) ||
          item?.address?.toLowerCase()?.includes(location?.toLowerCase())
      );
      console.log(filter);
      setAllDoctors(filter && filter);
    }
    if (location?.length > 0 && searchDoctor?.length > 0) {
      const getLocation = doctorData?.doctors?.filter(
        (item) =>
          item?.city?.toLowerCase()?.includes(location?.toLowerCase()) ||
          item?.address?.toLowerCase()?.includes(location?.toLowerCase())
      );
      const getDoctor = getLocation?.filter(
        (item) =>
          item?.main_specialization
            ?.toLowerCase()
            ?.includes(searchDoctor?.toLowerCase()) ||
          item?.secondary_specialization
            ?.toLowerCase()
            ?.includes(searchDoctor?.toLowerCase())
      );
      console.log(getDoctor);
      setAllDoctors(getDoctor && getDoctor);
    }
    if (location?.length === 0 && searchDoctor?.length === 0) {
      setAllDoctors(doctorData?.doctors && doctorData?.doctors);
    }
  };
  useEffect(() => {
    handleDoctorSearch();
  }, [location, searchDoctor]);

  return (
    <>
      <SubNav />
      <Navbar />
      <div className="alldoctor">
        {/* <div className="alldoctor_emergency">
          <div className="alldoctor_emergency_left">
            <p>Emergency number </p>
            <Report />
            <p>+91 1234567890</p>
          </div>
          <div className="alldoctor_emergency_right">
            <Button bg="#FFC107" color="#fff">
              Book for call
            </Button>
          </div>
        </div> */}
        <div className="alldoctor_doctor_search">
          <div className="alldoctor_doctor_search_left">
            <div className="alldoctor_doctor_search_left_top">
              <h6>Online Doctor Consultation</h6>

              <span>
                <div>
                  <input
                    type="text"
                    placeholder="Location"
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <LocationOn className="alldoctor_doctor_search_left_top_location" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="search a doctor"
                    onChange={(e) => setSearchDoctor(e.target.value)}
                  />
                  <Search className="alldoctor_doctor_search_left_top_search" />
                </div>
              </span>
            </div>
            {search && (
              <div className="alldoctor_doctor_search_left_bottom">
                <div className="doctorsearchfilter_top">
                  <span>
                    <Sort />
                    <p>Sort By</p>:
                    <select>
                      <option>Low to High</option>
                      <option>High to Low </option>
                    </select>
                  </span>
                  <span onClick={handleOpen} style={{ cursor: "pointer" }}>
                    <FilterAlt className="doctorsearchfilter_top_icon" />
                    <p>Filter</p>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="alldoctor_specialties_cards ">
          <div className="alldoctor_specialties_cards_heading">
            <h6> SPECIALTIES</h6>
          </div>

          <div className="alldoctor_specialties_card">
            {allDoctors?.length > 0 ? (
              allDoctors?.map((item, index) => (
                <DoctorCard
                  key={index + 1}
                  firstName={item?.first_name}
                  lastName={item?.last_name}
                  main_specialization={item?.main_specialization}
                  secondary_specialization={item?.secondary_specialization}
                  profile_image={item?.profile_image}
                  experience={item?.experience}
                  doctorId={item?.id}
                  zipCode={item?.zip_code}
                  address={item?.address}
                />
              ))
            ) : (
              <div className="nodatafound">
                <p>No Data Found</p>
              </div>
            )}
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <DateTimePicker onChange={onChange} value={value} />
          </Box>
        </Modal>
      </div>
      <Footer />
    </>
  );
}

export default AllDoctor;
