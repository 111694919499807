import React from "react";
import Dropdown from "../../../components/Dropdown";
import { useState } from "react";
import Button from "../../../components/Button";
import { Box, Modal } from "@mui/material";
import axios from "axios";

export default function SocialMediaSettings() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 550,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
    overflowY: "scroll",
  };
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  // Dropdown - Google Is Active
  const [selectedOption, setSelectedOption] = useState(null);
  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const options = [
    { label: "Enable", value: "opt1" },
    { label: "Disable", value: "opt2" },
  ];
  const [title, setTitle] = useState();
  const [clientId, setClientId] = useState();
  const [clientSerectKey, setClientSerectKey] = useState();
  const [isActive, setisActive] = useState();
  // Dropdown - Facebook Is Active
  const [selectedOption1, setSelectedOption1] = useState(null);
  const handleSelect1 = (option) => {
    setSelectedOption1(option);
  };

  const options1 = [
    { label: "Enable", value: true },
    { label: "Disable", value: false },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const addNewSocialLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("clink_id", clientId);
    formData.append("secret_key", clientSerectKey);
    formData.append("is_active", isActive);
    const { data } = await axios
      .post(`${process.env.React_App_Base_Url + "Create-Auth"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true), handleClose())
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    console.log(data);
  };

  return (
    <div className="flex flex-col gap-[2rem] p-[1rem]">
      <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
        <h3 className="text-[22px] font-semibold">Social Media Settings</h3>
        <div className="flex flex-row gap-[2rem]">
          {/* <Dropdown
            options={options}
            onChange={handleSelect}
            value={selectedOption}
          /> */}
          <Button
            onClick={handleOpen}
            className="flex h-[3.5rem] w-48 flex-row items-center justify-center gap-[4px] rounded-[0.25rem] bg-green-500 text-white shadow hover:bg-green-600"
          >
            <p>Add Social Media</p>
          </Button>
          {/* {showModal && modal} */}
        </div>
      </div>

      <div className="appearance-page flex flex-col gap-[1rem] border p-[1rem]">
        <h3 className="border-b text-[16px] font-semibold">Facebook Login</h3>
        <div action="" className="flex flex-col gap-[2rem] p-[1rem]">
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="">Facebook App Id</label>
            <input
              type="text"
              placeholder="Enter Google Client Id"
              className="border p-[1rem]"
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="">Facebook App Secret Key</label>
            <input
              type="text"
              placeholder="Enter Google Client Secret Key"
              className="border p-[1rem]"
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="">Is Active?</label>
            <Dropdown
              options={options1}
              onChange={handleSelect1}
              value={selectedOption1}
            />
          </div>
        </div>
        <Button success className="w-fit hover:bg-green-700">
          Save Now
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={addNewSocialLogin}>
            <div action="" className="flex w-full flex-col gap-[2rem] p-[1rem]">
              <div className="flex flex-col gap-[10px] ">
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="w-full border p-[1rem]"
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="">Client Id</label>
                <input
                  type="text"
                  placeholder="Enter Client Id"
                  className="border p-[1rem]"
                  onChange={(e) => setClientId(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="">Client Secret Key</label>
                <input
                  type="text"
                  placeholder="Enter Google Client Secret Key"
                  className="border p-[1rem]"
                  onChange={(e) => setClientSerectKey(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="">Is Active?</label>
                <select onChange={(e) => setisActive(e.target.value)} required>
                  <option>Selct One</option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </select>
              
              </div>
            </div>

            <button className="modal_form_buttom">
              Add Social Media Login
            </button>
          </form>
        </Box>
      </Modal>
      {/* <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={updateSubCategoryHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Edit Sub Category
            </Typography>
            <p className="modal_form_para">Sub Category</p>
            <span>
              <input
                type="text"
                placeholder="Sub Category Name"
                value={subCategoryName}
                onChange={(e) => setSubCategoryName(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Category</p>
            <span>
              <select
                onChange={(e) => setSelectedCategory(e.target.value)}
                required
              >
                <option>Select One Option</option>
                {allCategory?.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.categorieName}
                  </option>
                ))}
              </select>
            </span>

            <button className="modal_form_buttom">Add Sub Category</button>
          </form>
        </Box>
      </Modal> */}
    </div>
  );
}
