import React, { useState, useRef, useEffect, useContext } from "react";
import AuthContext from "../context api/StateProvider";

import "./AdminLogin.css";

import img1 from "./assets/login_img1.png";
import img2 from "./assets/login_img2.png";

import { AiOutlineMail } from "react-icons/ai";
import { BsEyeFill } from "react-icons/bs";

import axios from "axios";
// import { Login } from '@mui/icons-material';
import Admin from "./Admin";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { addAdminLogin } from "../Slice/adminLogin";

export default function AdminLogin() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    zIndex: "1111",
  };
  // Axios Login Authentication
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // const { setAuth } = useContext(AuthContext);

  const LOGIN_URL = "http://localhost:3001/admin";

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  // --------------------------
  const notify = () => toast.success("Login Successfully!");
  const notify1 = () => toast.warning("Something Went Wrong!");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const dispatch=useDispatch()
  const adminLoginHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", user);
    formData.append("password", pwd);
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "admin/login"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data) {
      notify();
      Cookies.set("admin", `${data?.data?.token}`, { expires: 7 });
      dispatch(addAdminLogin())
    }
    if (!data) {
      notify1();
    }

    console.log(data);
  };
  return (
    <>
      <div className="admin-login-section flex h-screen items-center justify-center p-[8rem]">
        <div className="flex h-full w-11/12 flex-row rounded-[12px] bg-[#ffffff] p-[1rem]">
          <div className="flex w-[50%] w-full flex-col items-center justify-center gap-[1rem] border-r-2 border-solid border-r-inherit">
            <img className="w-fit" src={img1} alt="img1" />
            <img className="h-fit w-[300px]" src={img2} alt="img2" />
          </div>

          <div className="flex flex-col gap-[1rem] p-[2rem]">
            <p
              ref={errRef}
              className={errorMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errorMsg}
            </p>
            <h3 className="text-[2rem] ">Welcome</h3>
            <form
              className="flex flex-col gap-[1rem] px-[2rem]"
              onSubmit={adminLoginHandle}
            >
              <div className="flex w-[300px] flex-row items-center justify-between rounded-[8px] border-2 border-solid border-inherit p-[1rem] transition hover:border-2 hover:border-solid hover:border-gray-600">
                <label htmlFor="username"></label>
                <input
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  className=""
                  id="username"
                  type="email"
                  placeholder="Email"
                  style={{ outline: "none" }}
                />
                <div>
                  <AiOutlineMail />
                </div>
              </div>
              <div className="flex w-[300px] flex-row items-center justify-between rounded-[8px] border-2 border-solid border-inherit p-[1rem] transition hover:border-2 hover:border-solid hover:border-gray-600">
                <label htmlFor="password"></label>
                <input
                  ref={userRef}
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                  id="password"
                  className=""
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  style={{ outline: "none" }}
                />
                <div>
                  <BsEyeFill
                    onClick={() => setShowPassword(!showPassword)}
                    className={
                      showPassword ? `text-[#1f1f1f]` : `text-gray-300`
                    }
                  />
                </div>
              </div>
              {isLoading ? (
                <button
                  className="w-fit rounded-[8px] bg-blue-500 px-[2rem] py-[1rem] font-semibold text-white hover:bg-blue-700"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <button
                  className="w-fit rounded-[8px] bg-blue-500 px-[2rem] py-[1rem] font-semibold text-white hover:bg-blue-700"
                  type="submit"
                >
                  Login
                </button>
              )}
            </form>
          </div>
        </div>
        {isLoading && (
          <Box sx={style}>
            <CircularProgress />
          </Box>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}
