import React, { useEffect, useRef, useState } from "react";
import "./ChatComponent.css";
import { Avatar } from "@mui/material";
import { Send } from "@mui/icons-material";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
function ChatComponent() {
  const [userDeatils, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const userId = Cookies.get("userId");
  const userName = Cookies.get("userName");
  const userEmail = Cookies.get("userEmail");
  const userMobile = Cookies.get("userMobile");
  const [allMessage, setAllMessage] = useState();
  const messagesRef = useRef(null);
  const { doctorId } = useParams();
  const { userLogin } = useSelector((state) => state.userlogin);
  const [doctor, setDoctor] = useState();
  const history = useNavigate();
  const [sendPredeFaultMessage, setSendPreDefaultMessage] = useState(false);
  const [loadOnce, setLoadOnce] = useState(true);
  const checkMessageHandle = async () => {
    const formData = new FormData();
    formData.append("uid", userId ? userId : "");
    formData.append("doctorId", doctorId ? doctorId : "");
    const data = await axios
      .post(
        `${process.env.React_App_Base_Url + "get-getchatbyuserid"}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data?.status === 203) {
      addInitalMessageHandle();
      console.log(data, "checkMessageHandle v");
    }
    if (data.status === 200) {
      console.log(data);
    }
  };
  const getMessageHandle = async () => {
    const formData = new FormData();
    formData.append("uid", userId);
    formData.append("doctorId", doctorId);
    formData.append("sendBy", "doctor");
    formData.append("userName", userName);
    formData.append("userMobile", userMobile);
    formData.append("preDefault", true);
    formData.append("text", "message");
    formData.append("userEmail", userEmail);
    const { data } = await axios
      .post(
        `${process.env.React_App_Base_Url + "create-allchatdata"}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    console.log(data);
  };
  useEffect(() => {
    checkMessageHandle();
  }, [userId, doctorId]);
  // const checkPredefaultMessage = () => {
  //   const q = query(
  //     collection(db, "messages"),
  //     orderBy("createdAt"),
  //     where("uid", "==", userId),
  //     where("doctorId", "==", Number(doctorId)),
  //     where("preDefault", "==", true)
  //   );
  //   // console.log(q[0])
  //   // Set up a real-time listener
  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const messageData = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     console.log(messageData[0], " preDefaultmessageData");
  //     {
  //       messageData?.length === 0 &&
  //         setSendPreDefaultMessage(true) &&
  //         loadOnce(false);
  //     }
  //   });
  //   // console.log(unsubscribe,'unsubscribe')
  // };

  const addInitalMessageHandle = async () => {
    try {
      const initialMessageData = {
        text: `Hii Caspian Health Care Doctor Consultant Please Explain Your Problem`,
        uid: userId,
        doctorId: Number(doctorId),
        sendBy: "doctor",
        userName: userName,
        userMobile: userMobile,
        userEmail: userEmail,
        preDefault: true,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, "messages"), initialMessageData);
      getMessageHandle();
      console.log("message snet now");
    } catch (error) {
      console.log("Error adding initial message:", error);
    }
  };

  useEffect(() => {
    // Create a query to order messages by createdAt time
    const q = query(
      collection(db, "messages"),
      orderBy("createdAt"),
      where("uid", "==", userId),
      where("doctorId", "==", Number(doctorId))
    );

    // Set up a real-time listener
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messageData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(messageData, "messageData");
      setAllMessage(messageData && messageData);
    });
    // Scroll the latest message into view
    if (messagesRef.current) {
      messagesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [db]);
  // Call the fetchData function

  const resetHandle = () => {
    setMessage("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add form data to Firestore collection
      await addDoc(collection(db, "messages"), {
        text: message,
        uid: userId,
        doctorId: Number(doctorId),
        sendBy: "user",
        userName: userName,
        userMobile: userMobile,
        userEmail: userEmail,
        createdAt: serverTimestamp(),
      });

      console.log("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
    }
    resetHandle();
  };

  useEffect(() => {
    console.log(allMessage, "allMessage");
  }, [allMessage]);
  useEffect(() => {
    // Scroll into view when messages change
    if (messagesRef.current) {
      messagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);
  useEffect(() => {
    console.log(userName, "userName");
  }, [userName]);

  if (!userLogin) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <p style={{ fontSize: "22px" }}> You Need To Login First</p>
        <button
          onClick={() => history("/")}
          style={{
            backgroundColor: "#6c98ff",
            color: "#fff",
            padding: " 5px 10px",
            borderRadius: "5px",
            fontSize: "18px",
            marginTop: "10px",
          }}
        >
          Home
        </button>
      </div>
    );
  }

  return (
    <div className="chatComponent">
      <div className="chatComponent_nav">
        <Avatar />
        <p style={{ marginLeft: "10px", fontSize: "22px", fontWeight: "600" }}>
          Caspian Health Care Doctor Consultant
        </p>
      </div>
      <div className="chatComponent_recent_chat">
        {allMessage?.map((item, index) => (
          <div
            className={
              item?.sendBy === "user"
                ? "chatComponent_recent_chat_paitent"
                : "chatComponent_recent_chat_doctor"
            }
          >
            {item.text?.toLowerCase().includes("https://") ? (
              <p>
                <a href={item.text} target="_blank">
                  {" "}
                  {item?.text}
                </a>
              </p>
            ) : (
              <p> {item.text}</p>
            )}
          </div>
        ))}

        {/* <div className="chatComponent_recent_chat_doctor">
          <p> Hello From Doctor Side</p>
        </div> */}
        <div ref={messagesRef} />
      </div>
      <div className="chatComponent_message">
        <span>
          <form onSubmit={handleSubmit}>
            <textarea
              style={{
                resize: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              placeholder="Write Your Problem Here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit">
              <Send
                style={{ color: "#fff", marginRight: "5px", fontSize: "35px" }}
              />
            </button>
          </form>
        </span>
      </div>
    </div>
  );
}

export default ChatComponent;
