import React from "react";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DialogBox from "../../components/DIalogBox/DialogBox";
// import Modal from '../../components/Modal';
import { useState } from "react";
import DragDropComponent from "../../components/DragDropComponent/DragDropComponent";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";

export default function Coupons() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 550,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
    overflowY: "scroll",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const [allCoupons, setAllCoupons] = useState();
  const [allCategory, setAllCategory] = useState();
  const [addCouponCode, setAddCouponCode] = useState();
  const [addCouponDiscountAmount, setAddCouponDiscountAmount] = useState();
  const [addCouponStartDate, setAddCouponStartDate] = useState();
  const [addCouponEndDate, setAddCouponEndDate] = useState();
  const [productId, setProductId] = useState();
  const [categoryId, setCategoryId] = useState();
  const [couponImage, setCouponImage] = useState();
  const [couponId, setCouponId] = useState();
  const getAllCategory = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "categories"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllCategory(data?.data && data?.data);
    // console.log(data);
  };
  const getAllCouponshandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-all-coupans"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllCoupons(data?.coupans && data?.coupans);
    console.log(data?.coupans);
  };
  const addProductCouponHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("coupan_code", addCouponCode);
    formData.append("product_id", productId);
    formData.append("category_id", "");
    formData.append("discount", addCouponDiscountAmount);
    formData.append("start_date", addCouponStartDate);
    formData.append("end_date", addCouponEndDate);
    formData.append("coupan_image", couponImage);
    const { data } = await axios
      .post(`${process.env.React_App_Base_Url + "Create-coupans"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true), handleClose())
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setProductId();
    setCategoryId();
    console.log(data);
    getAllCouponshandle()
  };
  const addCategoryCouponHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("coupan_code", addCouponCode);
    formData.append("product_id", "");
    formData.append("category_id", categoryId);
    formData.append("discount", addCouponDiscountAmount);
    formData.append("start_date", addCouponStartDate);
    formData.append("end_date", addCouponEndDate);
    formData.append("coupan_image", couponImage);
    const { data } = await axios
      .post(`${process.env.React_App_Base_Url + "Create-coupans"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then(
        (response) => response,
        setIsLoading(true),

        handleClose3()
      )
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setProductId();
    setCategoryId();
    console.log(data);
    getAllCouponshandle()
  };
  const getOneCouponHandle = async (id) => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-One-coupans/" + id}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAddCouponCode(data?.coupan && data?.coupan?.coupan_code);
    setAddCouponDiscountAmount(data?.coupan && data?.coupan?.discount);
    setProductId(data?.coupan && data?.coupan?.product_id);
    setCategoryId(data?.coupan && data?.coupan?.category_id);
    setAddCouponStartDate(data?.coupan && data?.coupan?.start_date);
    setAddCouponEndDate(data?.coupan && data?.coupan?.end_date);
    setCouponId(data?.coupan && data?.coupan?.id);
    console.log(data, "single blog");
  };
  const updateProductCouponHandle=async(e)=>{
    e.preventDefault();
    handleClose1()
    const formData =new FormData();
    formData.append("coupan_code", addCouponCode);
    formData.append("product_id", productId);
    formData.append("category_id", "");
    formData.append("discount", addCouponDiscountAmount);
    formData.append("start_date", addCouponStartDate);
    formData.append("end_date", addCouponEndDate);
    formData.append("coupan_image", couponImage);
    const data  = await axios
      .post(`${process.env.React_App_Base_Url+'Update-coupans/'+couponId}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
      getAllCouponshandle()
    console.log(data);
  }
  const updateCategoryCouponHandle=async(e)=>{
    e.preventDefault();
    handleClose4()
    const formData =new FormData();
    formData.append("coupan_code", addCouponCode);
    formData.append("product_id", "");
    formData.append("category_id", categoryId);
    formData.append("discount", addCouponDiscountAmount);
    formData.append("start_date", addCouponStartDate);
    formData.append("end_date", addCouponEndDate);
    formData.append("coupan_image", couponImage);
    const data  = await axios
      .post(`${process.env.React_App_Base_Url+'Update-coupans/'+couponId}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
      getAllCouponshandle()
    console.log(data);
  }
  const deleteCouponhandle=async()=>{
    handleClose2()
    const data = await axios
      .post(` ${process.env.React_App_Base_Url+'Delete-coupans/'+ couponId}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    console.log(data)
    getAllCouponshandle()
  }
  useEffect(() => {
    getAllCategory();
    getAllCouponshandle();
  }, []);
  useEffect(() => {
    console.log(allCoupons?.filter((item) => item?.category_id === null));
  }, [allCoupons]);
  // Add Coupon Data

  // let addCouponObjData = {
  //   code: addCouponCode,
  //   discountAmount: addCouponDiscountAmount,
  //   percentage: addCouponPercentage,
  //   startDate: addCouponStartDate,
  //   endDate: addCouponEndDate,
  // };

  const [addCouponData, setAddCouponData] = useState(null);

  // const handleSubmitAddCoupon = () => {
  //   setAddCouponData(addCouponObjData);
  //   console.log(addCouponData);
  // };

  const scrollModalStyle = {
    maxHeight: "calc(100vh - 6rem)",
    overflowY: "auto",
  };
  //Modal - Add Coupons
  const [showModal, setShowModal] = useState(false);

  // const modalContextAddCoupon = (
  //   <div className="appearance-page flex flex-col gap-[1rem] border p-[1rem]">
  //     <h3 className="border-b text-[16px] font-semibold">Basic Information</h3>
  //     <div className="flex flex-col gap-[10px]">
  //       <label htmlFor="">Coupon Code</label>
  //       <input
  //         value={addCouponCode}
  //         type="text"
  //         placeholder="Enter Coupon Code"
  //         className="border p-[1rem]"
  //         onChange={(event) => setAddCouponCode(event.target.value)}
  //       />
  //     </div>

  //     <div className="flex flex-row justify-between gap-[1rem]">
  //       <div className="flex w-full flex-col gap-[10px]">
  //         <label htmlFor="" className="">
  //           Discount Amount
  //         </label>
  //         <input
  //           value={addCouponDiscountAmount}
  //           type="text"
  //           className="w-full border p-[1rem]"
  //           placeholder="Enter Discount Amount"
  //           onChange={(event) => setAddCouponDiscountAmount(event.target.value)}
  //         />
  //       </div>
  //       <div className="flex w-full flex-col gap-[10px]">
  //         <label htmlFor="" className="">
  //           Percentage or Fixed
  //         </label>
  //         <input
  //           // value={addCouponPercentage}
  //           type="text"
  //           className="w-full border p-[1rem]"
  //           placeholder="Enter Percentage or Fixed"
  //           // onChange={(event) => setAddCouponPercentage(event.target.value)}
  //         />
  //       </div>
  //     </div>

  //     <div className="flex flex-row justify-between gap-[1rem]">
  //       <div className="flex w-full flex-col gap-[10px]">
  //         <label htmlFor="" className="">
  //           Start Date
  //         </label>
  //         <input
  //           // value={addCouponStartDate}
  //           type="date"
  //           className="w-full border p-[1rem]"
  //           placeholder="Enter Start Date"
  //           onChange={(event) => setAddCouponStartDate(event.target.value)}
  //         />
  //       </div>
  //       <div className="flex w-full flex-col gap-[10px]">
  //         <label htmlFor="" className="">
  //           End Date
  //         </label>
  //         <input
  //           // value={addCouponEndDate}
  //           type="date"
  //           className="w-full border p-[1rem]"
  //           placeholder="Enter End Date"
  //           onChange={(event) => setAddCouponEndDate(event.target.value)}
  //         />
  //       </div>
  //     </div>

  //     <div className="flex w-full flex-col gap-[10px]">
  //       <label htmlFor="" className="">
  //         Banner
  //       </label>
  //       <DragDropComponent />
  //     </div>
  //   </div>
  // );

  const modalContentAddProductCategories = (
    <div className="appearance-page flex flex-col gap-[1rem] border p-[1rem]">
      <h3 className="border-b text-[16px] font-semibold">
        Products & Categories
      </h3>

      <div className="flex flex-col gap-[10px]">
        <label htmlFor="">Categories</label>
        <input
          type="text"
          placeholder="Enter Categories"
          className="border p-[1rem]"
        />
      </div>

      <div className="flex flex-col gap-[10px]">
        <label htmlFor="">Products</label>
        <input
          type="text"
          placeholder="Enter Products"
          className="border p-[1rem]"
        />
      </div>
    </div>
  );

  const handleClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const actionBar = (
    <div>
      <Button onClick={closeModal} primary>
        Close
      </Button>
    </div>
  );

  // const modal = (
  //   <Modal
  //     //   modalHeading={'Add Coupons'}
  //     onClose={closeModal}
  //     actionBar={actionBar}
  //   >
  //     <div
  //       className="products-page flex flex-col gap-[2rem] p-[1rem]"
  //       style={scrollModalStyle}
  //     >
  //       <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
  //         <h3 className="text-[22px] font-semibold">Add Coupons</h3>
  //       </div>
  //       {modalContextAddCoupon}
  //       {modalContentAddProductCategories}
  //     </div>
  //     <Button
  //       onClick={handleSubmitAddCoupon}
  //       success
  //       className="w-fit hover:bg-green-700"
  //     >
  //       Save Now
  //     </Button>
  //   </Modal>
  // );
  // Modal End

  //Modal - Edit Coupons
  const [showModalEdit, setShowModalEdit] = useState(false);

  const modalContentEditCoupon = (
    <div className="appearance-page flex flex-col gap-[1rem] border p-[1rem]">
      <h3 className="border-b text-[16px] font-semibold">Basic Information</h3>
      <div className="flex flex-col gap-[10px]">
        <label htmlFor="">Coupon Code</label>
        <input
          type="text"
          placeholder="Enter Coupon Code"
          className="border p-[1rem]"
        />
      </div>

      <div className="flex flex-row justify-between gap-[1rem]">
        <div className="flex w-full flex-col gap-[10px]">
          <label htmlFor="" className="">
            Discount Amount
          </label>
          <input
            type="text"
            className="w-full border p-[1rem]"
            placeholder="Enter Discount Amount"
          />
        </div>
        <div className="flex w-full flex-col gap-[10px]">
          <label htmlFor="" className="">
            Percentage or Fixed
          </label>
          <input
            type="text"
            className="w-full border p-[1rem]"
            placeholder="Enter Percentage or Fixed"
          />
        </div>
      </div>

      <div className="flex flex-row justify-between gap-[1rem]">
        <div className="flex w-full flex-col gap-[10px]">
          <label htmlFor="" className="">
            Start Date
          </label>
          <input
            type="date"
            className="w-full border p-[1rem]"
            placeholder="Enter Start Date"
          />
        </div>
        <div className="flex w-full flex-col gap-[10px]">
          <label htmlFor="" className="">
            End Date
          </label>
          <input
            type="date"
            className="w-full border p-[1rem]"
            placeholder="Enter End Date"
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-[10px]">
        <label htmlFor="" className="">
          Banner
        </label>
        <DragDropComponent />
      </div>
    </div>
  );

  const modalContentProductCategories = (
    <div className="appearance-page flex flex-col gap-[1rem] border p-[1rem]">
      <h3 className="border-b text-[16px] font-semibold">
        Products & Categories
      </h3>

      <div className="flex flex-col gap-[10px]">
        <label htmlFor="">Categories</label>
        <input
          type="text"
          placeholder="Enter Categories"
          className="border p-[1rem]"
        />
      </div>

      <div className="flex flex-col gap-[10px]">
        <label htmlFor="">Products</label>
        <input
          type="text"
          placeholder="Enter Products"
          className="border p-[1rem]"
        />
      </div>
    </div>
  );

  const handleClickEdit = () => {
    setShowModalEdit(true);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const actionBarEdit = (
    <div>
      <Button onClick={closeModalEdit} primary>
        Close
      </Button>
    </div>
  );

  // const modalEdit = (
  //   <Modal
  //     //   modalHeading={'Add Coupons'}
  //     onClose={closeModalEdit}
  //     actionBar={actionBarEdit}
  //   >
  //     <div
  //       className="products-page flex flex-col gap-[2rem] p-[1rem]"
  //       style={scrollModalStyle}
  //     >
  //       <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
  //         <h3 className="text-[22px] font-semibold">Edit Coupon</h3>
  //       </div>
  //       {modalContentEditCoupon}
  //       {modalContentProductCategories}
  //     </div>
  //     <Button success className="w-fit hover:bg-green-700">
  //       Save Now
  //     </Button>
  //   </Modal>
  // );
  // Modal End

  //Table
  // /----------Dialog
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const data = [
    {
      id: "1",
      code: "Fahad Medicine",
      discountAmount: "My Brand",
      startDate: "Fresh",
      endDate: "$30,300",
    },
  ];

  const config = [
    {
      label: "S/N",
      render: (list) => list.id,
      sortValue: (list) => list.id,
    },
    {
      label: "Code",
      render: (list) => list.code,
      sortValue: (list) => list.code,
    },
    {
      label: "Discount Amount",
      render: (list) => list.discountAmount,
      sortValue: (list) => list.discountAmount,
    },
    {
      label: "Start Date",
      render: (list) => list.startDate,
      sortValue: (list) => list.startDate,
    },
    {
      label: "End Date",
      render: (list) => list.endDate,
      sortValue: (list) => list.endDate,
    },
    {
      label: "Action",
      render: (list) => (
        <div className="flex flex-col items-center justify-center gap-[10px]">
          <Button
            onClick={handleOpen}
            primary
            className="flex w-[100px] justify-center text-center"
          >
            Edit
          </Button>
          <Button
            danger
            className="flex w-[100px] justify-center text-center"
            onClick={handleOpen}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const keyFn = (list) => {
    return list.id;
  };
  return (
    <div className="products-page flex flex-col gap-[2rem] p-[1rem]">
      <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
        <h3 className="text-[22px] font-semibold">Coupons</h3>
        <div style={{ display: "flex" }}>
          <Button
            onClick={handleOpen}
            className="mr-5 flex h-[3rem] w-48 flex-row items-center justify-center gap-[4px] rounded-[0.25rem] bg-green-500 text-white shadow hover:bg-green-600"
          >
            <p>Add Coupon On Product</p>
          </Button>
          <Button
            onClick={handleOpen3}
            className="flex h-[3rem] w-48 flex-row items-center justify-center gap-[4px] rounded-[0.25rem] bg-green-500 text-white shadow hover:bg-green-600"
          >
            <p>Add Coupon On Category</p>
          </Button>
        </div>
      </div>
      <div className="adminorderpage_table_table">
        <h6
          style={{ fontSize: "22px", fontWeight: "500", marginBottom: "10px" }}
        >
          Product Coupons
        </h6>
        <table>
          <thead>
            <tr>
              <th>S/L</th>
              <th style={{ width: "250px" }}>Coupon Code</th>
              <th>Discount Amount</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allCoupons &&
              allCoupons
                ?.filter((item) => item?.category_id === null)
                ?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ width: "250px" }}>
                      <p>{item?.coupan_code}</p>
                    </td>
                    <td>{item?.discount}</td>
                    <td>{item?.start_date}</td>
                    <td>{item?.end_date}</td>

                    <td>
                      <Edit
                        style={{
                          color: "#6E798C",
                          marginLeft: "5px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                        // onClick={() => editProductHandle(item?.id)}
                        onClick={() => [
                          handleOpen1(),
                          getOneCouponHandle(item?.id),
                        ]}
                      />
                      <Delete
                        style={{
                          color: "#6E798C",
                          marginLeft: "5px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => [handleOpen2(), setCouponId(item?.id)]}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className="adminorderpage_table_table">
        <h6
          style={{ fontSize: "22px", fontWeight: "500", marginBottom: "10px" }}
        >
          Category Coupons
        </h6>
        <table>
          <thead>
            <tr>
              <th>S/L</th>
              <th style={{ width: "250px" }}>Coupon Code</th>
              <th>Discount Amount</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allCoupons
              ?.filter((item) => item?.product_id === null)
              ?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ width: "250px" }}>
                    <p>{item?.coupan_code}</p>
                  </td>
                  <td>{item?.discount}</td>
                  <td>{item?.start_date}</td>
                  <td>{item?.end_date}</td>

                  <td>
                    <Edit
                      style={{
                        color: "#6E798C",
                        marginLeft: "5px",
                        marginRight: "5px",
                        cursor: "pointer",
                      }}
                      // onClick={() => editProductHandle(item?.id)}
                      onClick={() => handleOpen4()}
                    />
                    <Delete
                      style={{
                        color: "#6E798C",
                        marginLeft: "5px",
                        marginRight: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => [handleOpen2(), setCouponId(item?.id)]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={addProductCouponHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Coupon On Product
            </Typography>
            <p className="modal_form_para">Coupon Code</p>
            <span>
              <input
                type="text"
                placeholder="Coupon Code"
                onChange={(e) => setAddCouponCode(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Discount Amount</p>
            <span>
              <input
                type="number"
                placeholder="Discount Amount"
                onChange={(e) => setAddCouponDiscountAmount(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Product Id</p>
            <span>
              <input
                type="number"
                placeholder="Product Id (you will get in All Product Section)"
                onChange={(e) => setProductId(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon Start Date</p>
            <span>
              <input
                type="date"
                onChange={(e) => setAddCouponStartDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon End Date</p>
            <span>
              <input
                type="date"
                onChange={(e) => setAddCouponEndDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon Image</p>
            <input
              type="file"
              onChange={(e) => setCouponImage(e.target.files[0])}
              required
            />

            <button className="modal_form_buttom">Add Coupon</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={updateProductCouponHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Update Coupon On Product
            </Typography>
            <p className="modal_form_para">Coupon Code</p>
            <span>
              <input
                type="text"
                placeholder="Coupon Code"
                value={addCouponCode}
                onChange={(e) => setAddCouponCode(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Discount Amount</p>
            <span>
              <input
                type="number"
                value={addCouponDiscountAmount}
                placeholder="Discount Amount"
                onChange={(e) => setAddCouponDiscountAmount(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Product Id</p>
            <span>
              <input
                type="number"
                value={productId}
                placeholder="Product Id (you will get in All Product Section)"
                onChange={(e) => setProductId(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon Start Date</p>
            <span>
              <input
                type="date"
              
                onChange={(e) => setAddCouponStartDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon End Date</p>
            <span>
              <input
                type="date"
                onChange={(e) => setAddCouponEndDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon Image</p>
            <input type="file" onChange={(e)=>setCouponImage(e.target.files[0])} required/>
            <button className="modal_form_buttom">Update Coupon</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={addCategoryCouponHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Coupon On Category
            </Typography>
            <p className="modal_form_para">Coupon Code</p>
            <span>
              <input
                type="text"
                placeholder="Coupon Code"
                onChange={(e) => setAddCouponCode(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Discount Amount</p>
            <span>
              <input
                type="text"
                placeholder="Discount Amount"
                onChange={(e) => setAddCouponDiscountAmount(e.target.value)}
                required
              />
            </span>

            <p className="modal_form_para">Category</p>
            <span>
              <select onChange={(e) => setCategoryId(e.target.value)}>
                <option value="">Select One Category</option>
                {allCategory?.map((item) => (
                  <option value={item?.id}>{item?.categorieName}</option>
                ))}
              </select>
            </span>
            <p className="modal_form_para">Coupon Start Date</p>
            <span>
              <input
                type="date"
                onChange={(e) => setAddCouponStartDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon End Date</p>
            <span>
              <input
                type="date"
                onChange={(e) => setAddCouponEndDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon Image</p>
            <input
              type="file"
              onChange={(e) => setCouponImage(e.target.files[0])}
            />

            <button className="modal_form_buttom">Add Coupon</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={updateCategoryCouponHandle}> 
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Update Coupon On Category
            </Typography>
            <p className="modal_form_para">Coupon Code</p>
            <span>
              <input
                type="text"
                placeholder="Coupon Code"
                value={addCouponCode}
                onChange={(e) => setAddCouponCode(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Discount Amount</p>
            <span>
              <input
                type="number"
                value={addCouponDiscountAmount}
                placeholder="Discount Amount"
                onChange={(e) => setAddCouponDiscountAmount(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Category</p>
            <span>
              <select onChange={(e) => setCategoryId(e.target.value)}>
                <option>Select One Category</option>
                {allCategory?.map((item) => (
                  <option value={item?.id}>{item?.categorieName}</option>
                ))}
              </select>
            </span>
            <p className="modal_form_para">Coupon Start Date</p>
            <span>
              <input
                type="date"
                onChange={(e) => setAddCouponStartDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon End Date</p>
            <span>
              <input
                type="date"
                onChange={(e) => setAddCouponEndDate(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Coupon Image</p>
            <input type="file" onChange={(e)=>setCouponImage(e.target.value)} required/>

            <button className="modal_form_buttom">Update Coupon</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You Sure ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <button className="button-cancel" onClick={handleClose2}>
              Cancel
            </button>
            <button className="button-proceed" onClick={deleteCouponhandle}>Proceed</button>
          </Typography>
        </Box>
      </Modal>
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
