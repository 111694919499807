import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./AllChat.css";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { data } from "autoprefixer";
import { ArrowBack, Send } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function AllChat() {
  const history = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const [allDoctors, setAllDoctors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getAllDoctorHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-all-Doctors"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllDoctors(data && data?.doctors);
    console.log(data);
  };
  useEffect(() => {
    getAllDoctorHandle();
  }, []);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [allMessage, setAllMessage] = useState([]);
  const getAllPaitentsWithDoctor = () => {
    const q = query(
      collection(db, "messages"),
      orderBy("createdAt"),
      where("doctorId", "==", Number(selectedDoctor))
    );

    // Set up a real-time listener
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messageData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(messageData, "messageData");
      setAllMessage(messageData && messageData);
    });

    // Scroll the latest message into view

    // Cleanup the listener when the component unmounts
  };
  useEffect(() => {
    getAllPaitentsWithDoctor();
  }, [selectedDoctor]);
  const [user, setUser] = useState();

  const [page, setPage] = useState("All Doctor");
  const messagesRef = useRef();
  const filterMessageHandle = () => {
    let idObject = [];

    // Iterate through the data array and group data by id
    allMessage.forEach((item) => {
      const { uid, ...rest } = item;

      if (!idObject[uid]) {
        // If the id is not in the object, create a new entry
        idObject[uid] = { uid, data: [rest] };
      } else {
        // If the id is already in the object, add data to the existing entry
        idObject[uid].data.push(rest);
      }
    });
    setUser(idObject && idObject);
    console.log(idObject, "idObject");
  };
  useEffect(() => {
    filterMessageHandle();
  }, [allMessage]);
  const [selectedUser, setSelectedUser] = useState("1");
  const [userMessage, setUserMessage] = useState();
  const [message, setMessage] = useState();
  useEffect(() => {
    const q = query(
      collection(db, "messages"),
      orderBy("createdAt"),
      where("uid", "==", selectedUser),
      where("doctorId", "==", Number(selectedDoctor))
    );

    // Set up a real-time listener
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messageData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(messageData, "messageData1");
      setUserMessage(messageData && messageData);
    });
    // Scroll the latest message into view
    if (messagesRef.current) {
      messagesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [db, selectedUser]);
  const resetHandle = () => {
    setMessage("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add form data to Firestore collection
      await addDoc(collection(db, "messages"), {
        text: message,
        uid: selectedUser,
        doctorId: Number(selectedDoctor),
        sendBy: "doctor",
        createdAt: serverTimestamp(),
      });

      console.log("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
    }
    resetHandle();
  };
  return (
    <div>
      {page === "All Doctor" && (
        <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
          <h3 className="text-[22px] font-semibold">All Docter Chats</h3>
          <div className="flex flex-row gap-[2rem]">
            {/* <Button
            onClick={handleOpen}
            className={`${showBtn} flex h-[3rem] w-48 flex-row items-center justify-center gap-[4px] rounded-[0.25rem] bg-green-500 text-white shadow hover:bg-green-600`}
          >
            <p>Add Doctor</p>
          </Button> */}
          </div>
        </div>
      )}
      {page === "All Patients" && (
        <div className="backbutton">
          <button onClick={() => setPage("All Doctor")}>
            <ArrowBack /> Back
          </button>
        </div>
      )}
      {page === "All Doctor" && (
        <div className="adminorderpage_table_table">
          <table>
            <thead>
              <tr>
                <th>S/L</th>
                <th style={{ width: "250px" }}>Doctor Profile</th>
                <th>Doctor Name</th>
              </tr>
            </thead>
            <tbody>
              {allDoctors?.map((item, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => [
                    setSelectedDoctor(item?.id),
                    setPage("All Patients"),
                  ]}
                >
                  <td>{index + 1}</td>
                  <td style={{ width: "250px" }}>
                    <img
                      src={
                        process.env.React_App_Base_Image_Url +
                        item?.profile_image
                      }
                      alt="doctor profile"
                      style={{ height: "80px" }}
                    />
                  </td>
                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {page === "All Patients" && (
        <div
          className="showAllPatientsByDoctor"
          style={{ paddingLeft: "2%", paddingTop: "20px", gap: "20px" }}
        >
          {user?.length > 0 ? (
            <div className="adminorderpage_table_table">
              <table>
                <thead>
                  <tr>
                    <th>Patient Id</th>
                    <th style={{ width: "250px" }}>Patient Name</th>
                    <th>Patient Email</th>
                    <th>Patient Mobile No</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.map((item, index = 0) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => [
                        setPage("Single Chat"),
                        setSelectedUser(item?.uid),
                      ]}
                    >
                      <td>{index}</td>
                      <td style={{ width: "250px" }}>
                        <p>{item?.data?.[0]?.userName}</p>
                      </td>
                      <td style={{ width: "250px" }}>
                        <p>{item?.data?.[0]?.userEmail}</p>
                      </td>
                      <td>
                        <p>{item?.data?.[0]?.userMobile}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="userNotFound">
              <p>No User Found</p>
            </div>
          )}
          {/* {user?.map((item) => (
            <span
              onClick={() => [
                setPage("Single Chat"),
                setSelectedUser(item?.uid),
              ]}
            >
              <p>
                {" "}
                Patient Name :-{" "}
                <span style={{ color: "#2a7fba" }}>Arman Ali</span>{" "}
              </p>
              <p>
                {" "}
                Patient EmailId :-
                <span style={{ color: "#2a7fba" }}>a@gmail.com</span>{" "}
              </p>
              <p>
                Patient Mobile NO :-
                <span style={{ color: "#2a7fba" }}>9876543210</span>{" "}
              </p>
            </span>
          ))} */}
        </div>
      )}
      {page === "Single Chat" && (
        <div className="backbutton backbutton1">
          <button onClick={() => setPage("All Patients")}>
            <ArrowBack /> Back
          </button>
          <a href="https://meet.google.com/" target="_blank">
            <button className="generateLink">Generate New Link</button>
          </a>
        </div>
      )}
      {page === "Single Chat" && (
        <div className="chatComponent" style={{ height: "100%" }}>
          <div
            className="chatComponent_recent_chat"
            style={{ width: "98%", height: "65vh", marginTop: "0" }}
          >
            {userMessage?.map((item) => (
              <div
                className={
                  item?.sendBy === "doctor"
                    ? "chatComponent_recent_chat_paitent"
                    : "chatComponent_recent_chat_doctor"
                }
              >
                {item.text?.toLowerCase().includes("https://") ? (
                  <p>
                    <a href={item.text} target="_blank">
                      {" "}
                      {item?.text}
                    </a>
                  </p>
                ) : (
                  <p> {item.text}</p>
                )}
              </div>
            ))}

            <div ref={messagesRef} />
          </div>
          <div className="chatComponent_message">
            <span>
              <form onSubmit={handleSubmit}>
                <textarea
                  style={{
                    resize: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  placeholder="Write Your Problem Here"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit">
                  <Send
                    style={{
                      color: "#fff",
                      marginRight: "5px",
                      fontSize: "35px",
                    }}
                  />
                </button>
              </form>
            </span>
          </div>
        </div>
      )}
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default AllChat;
