import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const isAdmin = Cookies.get("admin") ? true : false;
const initialState = {
  login: [],
  adminLogin: isAdmin,
};
export const adminLogin = createSlice({
  name: "adminLogin",
  initialState,
  reducers: {
    addAdminLogin: (state, action) => {
      state.adminLogin = true;
    },
    adminLogout: (state) => {
      state.adminLogin = false;
    },
  },
});
export const { addAdminLogin, adminLogout } = adminLogin.actions;
export default adminLogin.reducer;
