import React, { useEffect, useState } from "react";
import "./AboutUs.css";
import img from "../img/Rectangle119.jpg";
import img1 from "../img/Rectangle 94.jpg";
import img2 from "../img/hospital-img.jpg";

import Banner from "../Banner/Banner";
import { Link } from "react-router-dom";
import {
  Favorite,
  KeyboardArrowDown,
  LocalHospital,
  Visibility,
} from "@mui/icons-material";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import SubNav from "../Components/SubNav/SubNav";
import Navbar from "../Components/Navbar/Navbar";
import axios from "axios";
import HTMLReactParser from "html-react-parser";

const responsive = {
  0: {
    items: 1,
  },
  1024: {
    items: 1,
    itemsFit: "contain",
  },
};
function AboutUs() {
  const [responsibility, setResponsibility] = useState(true);
  const [heart, setHeart] = useState(false);
  const [eye, setEye] = useState(false);
  const viewHandle = (e) => {
    if (e.target.textContent === "OUR RESPONSIBILITY") {
      setResponsibility(!responsibility);
      setHeart(false);
      setEye(false);
    }
    if (e.target.textContent === "HEART SPECIALIST") {
      setResponsibility(false);
      setHeart(!heart);
      setEye(false);
    }

    if (e.target.textContent === "EYE SPECIALIST") {
      setResponsibility(false);
      setHeart(false);
      setEye(!eye);
    }
  };
  const [isLoading, setIsLoading] = useState();
  const [aboutUs, setAboutUs] = useState();
  const getAboutHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-About-us"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAboutUs(data && data[0]);
    console.log(data[0]);
  };
  useEffect(() => {
    getAboutHandle();
  }, []);
  const items = [
    <img
      src={
        aboutUs
          ? process.env.React_App_Base_Image_Url + aboutUs[0]?.image1
          : img1
      }
      role="presentation"
      className="alice_we_are"
    />,
    <img
      src={
        aboutUs
          ? process.env.React_App_Base_Image_Url + aboutUs[0]?.image2
          : img1
      }
      role="presentation"
      className="alice_we_are"
    />,
    <img
      src={
        aboutUs
          ? process.env.React_App_Base_Image_Url + aboutUs[0]?.image3
          : img1
      }
      role="presentation"
      className="alice_we_are"
    />,
  ];
  return (
    <div className="about">
      <SubNav />
      <Navbar />
      <div className="about_header">
        <Banner
          img={
            aboutUs
              ? process.env.React_App_Base_Image_Url + aboutUs[0]?.main_image
              : img
          }
        />
      </div>
      <div className="about_whychooseus">
        <div className="about_whychooseus_left">
          <h6>{aboutUs && aboutUs[0]?.title1}</h6>
          {HTMLReactParser(aboutUs ? aboutUs[0]?.text_for_title1 : "")}
        </div>
        <div className="about_whychooseus_right">
          <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            autoPlay={true}
            infinite
            autoPlayInterval={3000}
          />
         
        </div>
      </div>
      <div className="about_team">
        <div className="about_team_top">
          <h6>{aboutUs && aboutUs[0]?.title2}</h6>
          <p>{aboutUs && aboutUs[0]?.text_for_title2}</p>
        </div>
        <div className="about_team_bottom">
          <div className="about_team_bottom_card">
            <article>
              <div class="article-wrapper">
                <figure>
                  <img
                    src={
                      aboutUs
                        ? process.env.React_App_Base_Image_Url +
                          aboutUs[0]?.card1
                        : "https://picsum.photos/id/1011/800/450"
                    }
                    alt=""
                  />
                </figure>
                <div class="article-body">
                  <h2>{aboutUs && aboutUs[0]?.title_for_card_1}</h2>
                  <p>{aboutUs && aboutUs[0]?.text_for_card_1}</p>
                </div>
              </div>
            </article>
          </div>
          <div className="about_team_bottom_card">
            <article>
              <div class="article-wrapper">
                <figure>
                  <img
                    src={
                      aboutUs
                        ? process.env.React_App_Base_Image_Url +
                          aboutUs[0]?.card2
                        : "https://picsum.photos/id/1011/800/450"
                    }
                    alt=""
                  />
                </figure>
                <div class="article-body">
                  <h2>{aboutUs && aboutUs[0]?.title_for_card_2}</h2>
                  <p>{aboutUs && aboutUs[0]?.text_for_card_2}</p>
                </div>
              </div>
            </article>
          </div>
          <div className="about_team_bottom_card">
            <article>
              <div class="article-wrapper">
                <figure>
                  <img
                    src={
                      aboutUs
                        ? process.env.React_App_Base_Image_Url +
                          aboutUs[0]?.card3
                        : "https://picsum.photos/id/1011/800/450"
                    }
                    alt=""
                  />
                </figure>
                <div class="article-body">
                <h2>{aboutUs && aboutUs[0]?.title_for_card_3}</h2>
                  <p>{aboutUs && aboutUs[0]?.text_for_card_3}</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div className="about_our_hospital">
        <div className="about_our_hospital_left">
          <div className="about_our_hospital_left_heading">
            <h6>
              OUR <span style={{ fontWeight: "600" }}> HOSPITAL</span>
            </h6>
          </div>
          <div className="about_our_hospital_left_bottom">
            <img src={img2} alt="img" />
          </div>
        </div>
        <div className="about_our_hospital_right">
          <div className="about_our_hospital_right_heading">
            <h6>
              WHY <span style={{ fontWeight: "600" }}>CHOOSE CASPIAN</span>
            </h6>
          </div>
          <div className="about_our_hospital_right_bottom">
            <div className="about_our_hospital_right_bottom_one">
              <div className="about_our_hospital_right_bottom_one_heading">
                <span>
                  <LocalHospital style={{ color: "#2b96cc" }} />
                </span>
                <p onClick={viewHandle} style={{ cursor: "pointer" }}>
                  OUR RESPONSIBILITY
                </p>
                <KeyboardArrowDown />
              </div>
              {responsibility && (
                <div
                  className={
                    responsibility
                      ? "fade-in-top about_our_hospital_right_bottom_one_container"
                      : "fade-in-bottom about_our_hospital_right_bottom_one_container"
                  }
                >
                  <p>
                    Etharums ser quidem rerum facilis dolores nemis omnis fugats
                    vitaes nemo minima rerums unsers sadips amets. Sed ut
                    perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque laudantium, totam rem aperiam.
                  </p>
                  <p>
                    veritatis et quasi architecto beatae vitae dicta sunt
                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                    aspernatur aut odit aut fugit nemo minima rerums unsers
                    sadips amets. Sed ut perspiciatis unde sed quia
                    consequuntur. Page Maker including versions.
                  </p>
                </div>
              )}
            </div>
            <div className="about_our_hospital_right_bottom_one">
              <div className="about_our_hospital_right_bottom_one_heading">
                <span>
                  <Favorite style={{ color: "#2b96cc" }} />
                </span>
                <p onClick={viewHandle} style={{ cursor: "pointer" }}>
                  HEART SPECIALIST
                </p>
                <KeyboardArrowDown />
              </div>
              {heart && (
                <div
                  className={
                    heart
                      ? "fade-in-top about_our_hospital_right_bottom_one_container"
                      : "fade-in-bottom about_our_hospital_right_bottom_one_container"
                  }
                >
                  <p>
                    Etharums ser quidem rerum facilis dolores nemis omnis fugats
                    vitaes nemo minima rerums unsers sadips amets. Sed ut
                    perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque laudantium, totam rem aperiam.
                  </p>
                  <p>
                    veritatis et quasi architecto beatae vitae dicta sunt
                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                    aspernatur aut odit aut fugit nemo minima rerums unsers
                    sadips amets. Sed ut perspiciatis unde sed quia
                    consequuntur. Page Maker including versions.
                  </p>
                </div>
              )}
            </div>
            <div className="about_our_hospital_right_bottom_one">
              <div className="about_our_hospital_right_bottom_one_heading">
                <span>
                  <Visibility style={{ color: "#2b96cc" }} />
                </span>
                <p onClick={viewHandle} style={{ cursor: "pointer" }}>
                  EYE SPECIALIST
                </p>
                <KeyboardArrowDown />
              </div>
              {eye && (
                <div
                  className={
                    eye
                      ? "fade-in-top about_our_hospital_right_bottom_one_container"
                      : "fade-in-bottom about_our_hospital_right_bottom_one_container"
                  }
                >
                  <p>
                    Etharums ser quidem rerum facilis dolores nemis omnis fugats
                    vitaes nemo minima rerums unsers sadips amets. Sed ut
                    perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque laudantium, totam rem aperiam.
                  </p>
                  <p>
                    veritatis et quasi architecto beatae vitae dicta sunt
                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                    aspernatur aut odit aut fugit nemo minima rerums unsers
                    sadips amets. Sed ut perspiciatis unde sed quia
                    consequuntur. Page Maker including versions.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
