import { LocationOn } from "@mui/icons-material";
import React from "react";
import "./SubTotalCard.css";
import { useNavigate } from "react-router-dom";


function SubTotalCard({ submitbuttom, link, total }) {
  
const history =useNavigate()
  // console.log(cart?.length)
  // console.log(getCartTotal(cart),'hell')

  return (
    <div className="subtotalcard">
      <div className="subtotalcard_subtotal">
        <p>Sub Total</p>
        <strong>₹{total}</strong>
      </div>
      <div className="subtotalcard_shipping">
        <p>Shipping </p>
        <strong>₹200</strong>
      </div>
      <div className="subtotalcard_total">
        <p className="subtotalcard_total_total">Total (Tax incl.) &</p>
        <strong className="subtotalcard_total_total">
          ₹{total+ Number(200)}
        </strong>
      </div>
      <div className="subtotalcard_loaction">
        <p>Your delivery location</p>
        <small>
          <LocationOn className="subtotalcard_loaction_icon" /> lucknow
        </small>
      </div>
      <div className="subtotalcard_checkOut">
        <button className="button"  onClick={()=>history('/cartpay')}>
          CheckOut
        </button>
      </div>
    </div>
  );
}

export default SubTotalCard;
