import React, { useState } from "react";
import "./Categories.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
function Categories() {
  const { Category,brandId } = useParams();
  useEffect(() => {
    console.log(typeof Category);
  }, [Category]);
  const initailValue = {
    multiVitamin: false,
    vitamin: false,
    miniral: false,
    vitaminB12: false,
  };
  const [selected, setselected] = useState(initailValue);
  const [vitamin, setVitamin] = useState(false);
  const [multiVitamin, setMultiVitamin] = useState(false);
  const [miniral, setMiniral] = useState(false);
  const [vitaminB12, setVitaminB12] = useState(false);
  const getSelectedValue = (event) => {
    console.log(event.target.textContent);
    if (event.target.textContent === "Multivitamins") {
      setMultiVitamin(true);
      setMiniral(false);
      setVitamin(false);
      setVitaminB12(false);
    }
    if (event.target.textContent === "Vitamin B12 & B Complex") {
      setMultiVitamin(false);
      setMiniral(false);
      setVitamin(false);
      setVitaminB12(true);
    }
    if (event.target.textContent === "Mineral Supplements") {
      setMultiVitamin(false);
      setMiniral(true);
      setVitamin(false);
      setVitaminB12(false);
    }
    if (event.target.textContent === "Multivitamins") {
      setMultiVitamin(true);
      setMiniral(false);
      setVitamin(false);
      setVitaminB12(false);
    }
    console.log(multiVitamin, miniral, vitamin, vitaminB12);
  };
  const { categoryData } = useSelector((state) => state?.category);
  const { allbrands } = useSelector((state) => state?.brand);
  const history = useNavigate();
  return (
    <div className="categories">
      <h6>Categories </h6>
      <div className="categories_without_option">
        {categoryData?.data?.map((item) => (
          <span
            className={
              Number(Category) === item?.id &&
              "categories_without_option_active"
            }
            onClick={() => history(`/product/${item?.id}`)}
          >
            {item?.categorieName}
          </span>
        ))}
      </div>
      <div className="categories_with_option">
        <h6>Filters</h6>
        <div className="categories_without_option">
          <h6 style={{fontWeight:'500'}}> By Brands</h6>
          {allbrands?.data?.map((item) => (
            <span  className={
              Number(brandId) === item?.id &&
              "categories_without_option_active"
            }
            onClick={()=>history(`/brand/brand/${item?.id}`)}
            >{item?.brandname}</span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Categories;
