import React, { useEffect, useState } from "react";
import "./Product_Card.css";
import img1 from ".././img/image 4.jpg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { cartAllData } from "../Slice/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { openFormHandle } from "../Slice/userLogin";

function Product_Card({
  img,
  title,
  mrp,
  price,
  productUnit,
  quantity,
  product_id,
}) {
  const history = useNavigate();
  const notify = () => toast.success("Successfully Add To Cart!");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userId = Cookies.get("userId");
  const { userLogin } = useSelector((state) => state?.userlogin);
  const { cart } = useSelector((state) => state?.cartData);
  const [isProduct, setIsProduct] = useState(false);
  useEffect(() => {
    cart?.find((item) => item?.product_id === product_id)
      ? setIsProduct(true)
      : setIsProduct(false);
  }, [cart, product_id, userId]);

  const addToCard = async () => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("product_id", product_id);
    formData.append("number_of_item", 1);
    formData.append("per_item_price", price);
    formData.append("total_price", price);
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "Create-carts"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data) {
      notify();
      dispatch(cartAllData());
    }

    console.log(data);
  };

  return (
    <div className="productCard">
      <div
        className="productCard_img"
        onClick={() => history(`/singlepage/${product_id}`)}
      >
        <img
          src={img ? process.env.React_App_Base_Image_Url + img : img1}
          alt="product"
        />
      </div>
      <div
        className="productCard_details"
        onClick={() => history(`/singlepage/${product_id}`)}
      >
        <h6>{title ? title : "DETTOL"}</h6>
        <p>
          {quantity} {productUnit}
        </p>
        <p
          style={{
            color: "rgb(108, 152, 255)",
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          <s style={{ color: "lightgrey" }}>₹{mrp}</s> ₹{price}
        </p>
      </div>

      {userLogin ? (
        isProduct ? (
          <button
            className="productCard_button"
            onClick={() => history("/cart")}
          >
            Go To Cart
          </button>
        ) : !isLoading ? (
          <button className="productCard_button" onClick={addToCard}>
            Add To Cart
          </button>
        ) : (
          <button className="productCard_button">Adding...</button>
        )
      ) : (
        <button
          className="productCard_button"
          onClick={() => dispatch(openFormHandle())}
        >
          Add To Card
        </button>
      )}

      {/* <Button color='#fff' bg='#2a7fba'>Add to Cart</Button> */}
    </div>
  );
}

export default Product_Card;
