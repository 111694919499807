import { Star } from "@mui/icons-material";
import React from "react";
import "./RatingCard.css";
function RatingCard({ name, message }) {
  return (
    <div className="ratingCard">
      <h6>{name}</h6>
      <span>
        <p className="ratingCard_rating">
          4.5 <Star style={{fontSize:"12px"}}/>
        </p>
        <p className="ratingCard_verified"> Verified Buyer</p>
      </span>
      <p>{message}</p>
    </div>
  );
}

export default RatingCard;
