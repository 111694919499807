import {
  AddShoppingCart,
  ArrowForwardIos,
  Face,
  KeyboardArrowDown,
  Search,
  ShoppingCart,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import logo from "../../img/Rectangle.jpg";
import { useState } from "react";
import { motion } from "framer-motion";
import SubNav from "../SubNav/SubNav";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import { userLogout } from "../../Slice/userLogin";
import { ToastContainer, toast } from "react-toastify";
function Navbar() {
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    zIndex: "1111",
  };
  const history = useNavigate();
  const openRef = useRef();
  const sidebarRef = useRef();
  const [sideBar, setSideBar] = useState(false);
  const menuHandle = () => {
    setSideBar(!sideBar);
  };
  const [open, setOpen] = useState(false);
  const [loginHandle, setLoginHandle] = useState(false);
  const [signUpHandle, setSignUpHandle] = useState(false);
  const openHandle = (e) => {
    console.log(e.target.textContent);
    if (e.target.textContent === "Login") {
      setLoginHandle(true);
      setSignUpHandle(false);
    }
    if (e.target.textContent === "SignUp") {
      setLoginHandle(false);
      setSignUpHandle(true);
    }
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
    console.log(open);
  };
  const { categoryData } = useSelector((state) => state?.category);
  const { subCategoryData } = useSelector((state) => state.subCategory);
  const { Product } = useSelector((state) => state.Product);
  const [openSearchTab, setOpenSearchTab] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResultData, setSearchResultData] = useState();
  const { userLogin } = useSelector((state) => state.userlogin);
  const { cart } = useSelector((state) => state.cartData);
  const searchReasult = async () => {
    const result = await Product?.product?.filter((item) => {
      if (search !== "") {
        // console.log(item.productName.toLowerCase().includes(search.toLowerCase()))
        return item.productName.toLowerCase().includes(search.toLowerCase());
      }
    });
    console.log(result);
    setSearchResultData(result && result);
  };
  useEffect(() => {
    searchReasult();
  }, [search]);
  useEffect(() => {
    let handler = (e) => {
      if (!openRef.current.contains(e.target)) {
        setOpenSearchTab(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  const dispatch = useDispatch();
  const notify1 = () => toast.warning("Something Went Wrong!");
  const notify2 = () => toast.success("Logout Successfully!");
  const [isLoading, setIsLoading] = useState(false);
  const userTokenCookies = Cookies.get("userToken");
  const userLogoutHandle = async () => {
    const formData = new FormData();

    const data = await axios
      .post(`${process.env.React_App_Base_Url + "logout-user"}`, formData, {
        headers: {
          Authorization: `${userTokenCookies}`,
          "Content-type": "multipart/form-date",
        },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data?.status === 200) {
      notify2();
      history("/");
      Cookies.remove("userToken");
      dispatch(userLogout());
    }
    if (!data) {
      notify1();
    }
    console.log(data);
  };
  return (
    <>
      <div className="subnavdisplaynone">
        <SubNav
          formOpen={open}
          loginHandle={loginHandle}
          signUpHandle={signUpHandle}
        />
      </div>

      <div className="navbar">
        <div className="navbar_link_div">
          <Link className="navbar_link" to="/">
            Home
          </Link>
          {/* <Link className="navbar_link" to="/about">
          About Us
        </Link> */}
          {categoryData?.data?.slice(0, 4)?.map((item, index) => (
            <Link className="navbar_link dropdown navbar_link_center" to="#">
              <p className="dropbtn" key={index}>
                {item?.categorieName} <KeyboardArrowDown />
              </p>

              <div className="dropdown-content">
                {subCategoryData?.sub_Categories
                  ?.filter((data) => data?.categorie_id === item?.id)
                  ?.map((item, index) => (
                    <Link
                      key={index}
                      to={`/product/${item?.categorie_id}/${item?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {item?.sub__categories_name}
                    </Link>
                  ))}
              </div>
            </Link>
          ))}
        </div>

        <div className="navabr_search" ref={openRef}>
          <>
            <input
              type="text"
              placeholder="Search Health Products"
              onChange={(e) => setSearch(e.target.value)}
              onClick={() => setOpenSearchTab(true)}
            />
            <Search className="navabr_search_icon" />
          </>
          {openSearchTab && (
            <div style={{ width: "100%", background: "#fff" }}>
              {searchResultData?.length > 0 ? (
                searchResultData?.map((item) => (
                  <p onClick={() => history(`/singlePage/${item?.id}`)}>
                    {" "}
                    {item?.productName}
                  </p>
                ))
              ) : (
                <p style={{ border: "none" }}>No Result Found</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="navbar_mobile">
        <div className="navbar_mobile_left">
          <MenuIcon
            className="navbar_mobile_left_icon"
            onClick={menuHandle}
            style={{ fontSize: "35px" }}
          />
          <img src={logo} onClick={() => history("/")} />
        </div>
        <div className="navbar_mobile_right" style={{ marginRight: "20px" }}>
          {/* {userLogin &&  <AddShoppingCart onClick={() => history("/cart")} />} */}
          <Link className="subnav_cart_link" to="/cart">
            <ShoppingCart />
            {userLogin ? (
              <span className="subnav_cart_count">{cart?.length}</span>
            ) : (
              ""
            )}
          </Link>
        </div>
      </div>
      {sideBar && (
        <>
          <motion.div
            className="mobile_menu sidenav"
            initial={{ x: "-100vw" }}
            animate={{ x: 0 }}
            transition={{ delay: 0.1 }}
            onClick={() => setSideBar(false)}
          >
            <div className="mobile_menu_login">
              <div className="mobile_menu_login_left">
                <Face className="mobile_menu_login_left_icon" />
                <span>
                  <h6>Hi,there</h6>
                  {userLogin ? (
                    <p onClick={() => history("/profilepage")}>MyProfile</p>
                  ) : (
                    <p>
                      <span
                        style={{ color: "#28A745", fontWeight: "bold" }}
                        onClick={openHandle}
                      >
                        Login
                      </span>{" "}
                      /{" "}
                      <span
                        style={{ color: "#2a7fba", fontWeight: "bold" }}
                        onClick={openHandle}
                      >
                        SignUp
                      </span>
                    </p>
                  )}
                </span>
              </div>
              <div className="mobile_menu_login_right">
                <ArrowForwardIos />
              </div>
            </div>
            <div className="mobile_menu_buy_medicine">
              {categoryData?.data?.map((item) => (
                <span onClick={() => history(`/product/${item?.id}`)}>
                  <p>{item?.categorieName}</p>
                </span>
              ))}

              <span onClick={() => history("/labtest")}>
                <p>Book a Lab Test</p>
              </span>
              <span onClick={() => history("/doctorhomepage")}>
                <p>Consult a Doctor</p>
              </span>

              <span>
                <p>All Diseases</p>
              </span>
              <span>
                <p>All Medicine</p>
              </span>
              <span>
                <p>Medicine by therope Class</p>
              </span>
              <span onClick={userLogoutHandle}>
                <p>Logout</p>
              </span>
            </div>
            <div className="mobile_menu_buy_contact">
              <span>
                <p>Contact Us</p>
              </span>
              <span>
                <p> Need help ?</p>
              </span>
            </div>
          </motion.div>
        </>
      )}
      {isLoading && (
        <Box sx={style1}>
          <CircularProgress />
        </Box>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Navbar;
