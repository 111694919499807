import React, { useEffect, useState } from "react";
import DragDropComponent from "../../components/DragDropComponent/DragDropComponent";
import Button from "../../components/Button";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

export default function AccountSettings() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const notify = () => toast.success("Updated Successfully!");
  const notify1 = () => toast.warning("Something Went Wrong!");
  const [isLoading, setIsLoading] = useState();
  const [adminName, setAdminName] = useState();
  const [adminPhone, setAdminPhone] = useState();
  const [adminEmail, setAdminEmail] = useState();
  const [adminPassword, setAdminPassword] = useState();
  const [adminProfile, setAdminProfile] = useState([]);
  const [adminProfilePrev, setAdminProfilePrev] = useState([]);
  const [adminId, setAdminId] = useState();
  const getAdminData = async () => {
    const { data } = await axios
      .post(`${process.env.React_App_Base_Url + "admin-data"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAdminName(data && data?.admin?.adminName);
    setAdminPhone(data && data?.admin?.phoneNumber);
    setAdminEmail(data && data?.admin?.email);
    setAdminProfilePrev(data && data?.admin?.Avatar);
    setAdminId(data && data?.admin?.id);
    console.log(data);
  };
  const updateAdminData = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("adminName", adminName);
    formData.append("phoneNumber", adminPhone);
    formData.append("email", adminEmail);
    formData.append("image", adminProfile);
    formData.append("password", adminPassword);
    const { data } = await axios
      .post(
        `${process.env.React_App_Base_Url + "admin-update/" + adminId}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
      if(data){
       notify()
      }
      if(!data){
       notify1()
      }

    console.log(data);
  };
  useEffect(() => {
    getAdminData();
  }, []);
  useEffect(()=>{
    console.log(adminProfile)
  },[adminProfile])
  return (
    <div className="products-page flex flex-col gap-[2rem] p-[1rem]">
      <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
        <h3 className="text-[22px] font-semibold">Account Settings</h3>
      </div>
      <form
        className="appearance-page flex flex-col gap-[1rem] border p-[1rem]"
        onSubmit={updateAdminData}
      >

        <div className="flex flex-col gap-[10px]">
          <label htmlFor="">Name</label>
          <input
            type="text"
            className="w-full border p-[1rem]"
            placeholder="Enter Name"
            value={adminName}
            onChange={(e) => setAdminName(e.target.value)}
          />
          <label htmlFor="">Phone</label>
          <input
            type="text"
            className="w-full border p-[1rem]"
            placeholder="Enter Phone"
            value={adminPhone}
            onChange={(e) => setAdminPhone(e.target.value)}
          />
          <label htmlFor="">Email</label>
          <input
            type="text"
            className="w-full border p-[1rem]"
            placeholder="Enter Email"
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
          />
          <label htmlFor="">Profile Image</label>
          <input type="file" onChange={(e)=>setAdminProfile(e.target.files[0])}/>
          <div className="flex justify-start"> 
          {
            adminProfilePrev?<img src={adminProfile?.length===0?process.env.React_App_Base_Image_Url+adminProfilePrev:(URL.createObjectURL(adminProfile))} style={{height:'120px',objectFit:'contain'}} alt="admin profile" />:<img src={adminProfile?.length===0?'':(URL.createObjectURL(adminProfile))} style={{height:'120px',objectFit:'contain'}} alt="admin profile" />
          }

          </div>
      
         
          
          <label htmlFor="">Password</label>
          <input
            type="password"
            className="w-full border p-[1rem]"
            placeholder="Enter Password"
            onChange={(e) => setAdminPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="w-fit text-white bg-green-600 p-2 rounded-sm">
          Save Now
        </button>
      </form>
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
         <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
