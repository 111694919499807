import React from 'react'
import './Banner.css'
function Banner({img}) {
  return (
    <div className='banner'>
        <img src={img} alt='banner1'/>
    </div>
  )
}

export default Banner