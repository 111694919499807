import React from "react";
import "./LargestHealthCard.css";
function LargestHealthCard() {
  return (
    <div className="largesthealthcard">
      <div className="largesthealthcard_heading">
        <h6>INDIA’S LARGEST HEALTHCARE PLATFORM</h6>
      </div>
      <div className="largesthealthcard_card">

        <span>
          <div>
            <strong>260k+</strong>
            <p>Visitors</p>
          </div>
          <div>
            <strong>60k+</strong>
            <p>Orders Delivered</p>
          </div>
          <div>
            <strong>950+</strong>
            <p>Cities</p>
          </div>
        </span>
      </div>
    </div>
  );
}

export default LargestHealthCard;
