import {  createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
const userId=Cookies.get('userId')
const initialState = {
  cart: [],
  loading:false
};
export const cartAllData = createAsyncThunk("cartAllData", async () => {
    const { data } = await axios.post(
      `${process.env.React_App_Base_Url + "get-carts/user/"+userId}`
    );
    // console.log(data,"datat daya")
    return data?.carts;
  });
export const cartData = createSlice({
  name: "cartData",
  initialState,
  reducers: {
    add_to_cart:(state,action)=>{
        state.cart=(action.payload)
    }
  },
  extraReducers: {
    [cartAllData.pending]: (state) => {
        state.loading = true;
      },
       [cartAllData.rejected]: (state) => {
        state.loading = false;
      },
    [cartAllData.fulfilled]: (state, action) => {
      state.cart = action.payload;
      state.loading = false;
    },
  },
  
});
export const {
    add_to_cart
   
  } = cartData.actions;
export default cartData.reducer;
