import React, { useEffect } from "react";
import { useState } from "react";
import Dropdown from "../../../components/Dropdown";
import DragDropComponent from "../../../components/DragDropComponent/DragDropComponent";
import Button from "../../../components/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default function GeneralSettings() {
  // Dropdown - System Title
  const [selectedOption, setSelectedOption] = useState(null);
  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const options = [
    { label: "Enable", value: "opt1" },
    { label: "Disable", value: "opt2" },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [generalSystemSettingId, setGeneralSystemSettingId] = useState();
  const [websiteLogo, setWebsiteLogo] = useState([]);
  const [websiteLogoPrev, setWebsiteLogoPrev] = useState();
  const [websiteFivicon, setWebsiteFivicon] = useState([]);
  const [websiteFiviconPrev, setWebsiteFiviconPrev] = useState();
  const [websiteTitle, setWebsiteTitle] = useState();
  const [websiteAddress, setWebsiteAddress] = useState();
  const notify = () => toast.success("Successfully Submitted!");
  const notify1 = () => toast.warning("Something Went Wrong!");
  const allGeneralSystemSetteing = async () => {
    const data = await axios
      .get(`${process.env.React_App_Base_Url + "GenaralSetting-get-all"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setGeneralSystemSettingId(data && data?.data[0]?.id);
    setWebsiteFiviconPrev(data && data?.data[0]?.favicon_image);
    setWebsiteLogoPrev(data && data?.data[0]?.logo_image);
    setWebsiteAddress(data && data?.data[0]?.website_address);
    setWebsiteTitle(data && data?.data[0]?.website_title);

    console.log(data, "all data");
  };
  const generalDataSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("logo_image", websiteLogo);
    formData.append("favicon_image", websiteFivicon);
    formData.append("website_title", websiteTitle);
    formData.append("website_address", websiteAddress);
    const data = await axios
      .post(
        `${
          process.env.React_App_Base_Url +
          "GenaralSetting-update/" +
          generalSystemSettingId
        }`,
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
      if(data){
        notify()
      }
      if(!data){
        notify1()
      }

    console.log(data);
  };
  useEffect(() => {
    allGeneralSystemSetteing();
  }, []);
  return (
    <div className="products-page flex flex-col gap-[2rem] p-[1rem]">
      <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
        <h3 className="text-[22px] font-semibold">General Settings</h3>
      </div>

      <div className="appearance-page flex flex-col gap-[1rem] border p-[1rem]">
        <h3 className="border-b text-[16px] font-semibold">
          General Information
        </h3>
        <form onSubmit={generalDataSubmit}>
          <div action="" className="flex flex-col gap-[2rem] p-[1rem]">
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="">Website Title</label>
              <input
                type="text"
                placeholder="Website Title"
                className="border p-[1rem]"
                value={websiteTitle}
                onChange={(e) => setWebsiteTitle(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="">Website Address</label>
              <input
                type="text"
                placeholder="Enter Address"
                className="border p-[1rem]"
                value={websiteAddress}
                onChange={(e) => setWebsiteAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="appearance-page flex flex-col gap-[1rem] border p-[1rem]">
            <h3 className="border-b text-[16px] font-semibold">
              Website Logo & Favicon
            </h3>
            <div className="flex flex-col gap-[2rem] p-[1rem]">
              <div className="flex flex-col gap-[10px]">
              {websiteLogo?.length===0? <img src={process.env.React_App_Base_Image_Url+websiteLogoPrev} alt="favicon" style={{height:"120px",objectFit:'contain'}}/>:<img src={URL.createObjectURL(websiteLogo)}  style={{height:"120px",objectFit:'contain'}}/>}
                <label htmlFor="">Logo Image</label>
                <input
                  type="file"
                  placeholder="Enter Address"
                  className=" p-[1rem]"
                  onChange={(e) => setWebsiteLogo(e.target.files[0])}
                />
              </div>
              <div className="flex flex-col gap-[10px]">
             {websiteFivicon?.length===0? <img src={process.env.React_App_Base_Image_Url+websiteFiviconPrev} alt="favicon" style={{height:"120px",objectFit:'contain'}}/>:<img src={URL.createObjectURL(websiteFivicon)}  style={{height:"120px",objectFit:'contain'}}/>}
                <label htmlFor="">Favicon Image</label>
                <input
                  type="file"
                  placeholder="Enter Address"
                  className="p-[1rem]"
                  onChange={(e) => setWebsiteFivicon(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <Button success className="w-fit hover:bg-green-700">
            Save Now
          </Button>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
