import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Edit } from "@mui/icons-material";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import axios from "axios";

function AddDoctorPrice() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 550,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
    overflowY: "scroll",
  };
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [isLoading, setIsLoading] = useState();
  const [allDoctors, setAllDoctors] = useState();
  const getAllDoctorHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-all-Doctors"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllDoctors(data && data?.doctors);
    console.log(data, "doctor");
  };
  useEffect(() => {
    getAllDoctorHandle();
  }, []);
  return (
    <div>
      <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
        <h3 className="text-[22px] font-semibold">All Avaliable Docter</h3>
        <div className="flex flex-row gap-[2rem]">
          <Button
            onClick={handleOpen}
            className={` flex h-[3rem] w-48 flex-row items-center justify-center gap-[4px] rounded-[0.25rem] bg-green-500 text-white shadow hover:bg-green-600`}
          >
            <p>Add Doctor Price</p>
          </Button>
        </div>
      </div>
      <div className="adminorderpage_table_table">
        <table>
          <thead>
            <tr>
              <th>S/L</th>
              <th style={{ width: "250px" }}>Doctor Name</th>
              <th>Price</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td style={{ width: "250px" }}>
                <p>Doctor Name</p>
              </td>
              <td>500</td>
              <td>
                <Edit
                  style={{
                    color: "#6E798C",
                    marginLeft: "5px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  // onClick={() => editSubCategoryHandle(item?.id)}
                  onClick={() => [handleOpen1()]}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Doctor Price
            </Typography>
            <p className="modal_form_para">Select One Doctor</p>
            <span>
              <select>
                <option value="">Select One Doctor</option>
                {allDoctors?.map((item) => (
                  <option value="">
                    {item?.first_name} {item?.last_name}
                  </option>
                ))}
              </select>
            </span>
            <p className="modal_form_para">Doctor Price</p>
            <span>
              <input
                type="text"
                placeholder="2000"
                //   onChange={(e) => setDoctorProfile(e.target.files[0])}
                required
              />
            </span>

            <button className="modal_form_buttom">Add Doctor Price</button>
          </form>
        </Box>
      </Modal>  
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Doctor Price
            </Typography>
            <p className="modal_form_para">Select One Doctor</p>
            <span>
              <select>
                <option value="">Select One Doctor</option>
                {allDoctors?.map((item) => (
                  <option value="">
                    {item?.first_name} {item?.last_name}
                  </option>
                ))}
              </select>
            </span>
            <p className="modal_form_para">Doctor Price</p>
            <span>
              <input
                type="text"
                placeholder="2000"
                //   onChange={(e) => setDoctorProfile(e.target.files[0])}
                required
              />
            </span>

            <button className="modal_form_buttom">Add Doctor Price</button>
          </form>
        </Box>
      </Modal>
      {isLoading && (
          <Box sx={style}>
            <CircularProgress />
          </Box>
        )}
    </div>
  );
}

export default AddDoctorPrice;
