import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  Product: [],
  categoryWiseProduct: [],
  loading:false
};
export const allProduct = createAsyncThunk("allProduct", async () => {
  const { data } = await axios.post(
    `${process.env.React_App_Base_Url + "product/get-all-product"}`
  );
  return data;
});
export const Product = createSlice({
  name: "Product",
  initialState,
  reducers: {},
  extraReducers: {
    [allProduct.pending]: (state, action) => {
        state.loading = true;
      },
       [allProduct.rejected]: (state, action) => {
        state.loading = false;
      },
    [allProduct.fulfilled]: (state, action) => {
      state.Product = action.payload;
      state.loading = false;
    },
  },
});
export default Product.reducer;
