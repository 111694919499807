import React from "react";
import "./HealthCheckOutCard.css";
function HealthCheckOutCard() {
  return (
    <div className="healthcheckoutCard">
      <h6>Check the health of your vital organs</h6>
      <div className="healthcheckoutCard_checkout">
        <input type="checkbox" />
       <p> Lorem ipsum dolor sit amet consectetur. Enim aliquet elementum turpis
        nulla in ac rutrum magna. Ultricies commodo pharetra congue fermentum.
        Elit et magna nunc</p>
      </div>
    </div>
  );
}

export default HealthCheckOutCard;
