import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import axios from "axios";
import { Edit, Visibility } from "@mui/icons-material";
import BasicPagination from "../../../Pagination/Pagination";
import { check } from "prettier";
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

function AllReviews() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid transparent",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState();
  const [allReviews, setAllReviews] = useState();
  const [UserName, setUserName] = useState();
  const [singeMessage, setSingeMessage] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const notify = () => toast.success("Successfully Submitted!");
  const notify1 = () => toast.warning("Something Went Wrong!");
  const getAllReviewHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-all-reviews"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllReviews(data && data);
    console.log(data);
  };
  const getEditReviewHandle = async (id, value) => {
    const formData = new FormData();
    formData.append("published", value === 1 ? 0 : 1);
    const { data } = await axios
      .post(
        `${process.env.React_App_Base_Url + "update-reviews/" + id}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    // if (data) {
    //   notify();
    // }
    // if (!data) {
    //   notify1();
    // }
    getAllReviewHandle();
    console.log(data);
  };
  useEffect(() => {
    getAllReviewHandle();
  }, []);
  useEffect(() => {
    setCount(Math.ceil(allReviews?.length / 10).toFixed(0));
  }, [allReviews]);
  const filterReviewHandle = (id) => {
    const filter = allReviews?.find((item) => item?.id === id);
    setUserName(filter && filter?.name);
    setSingeMessage(filter && filter?.message);
    console.log(filter);
  };
  return (
    <div>
      <div className="products-page flex flex-col gap-[2rem] p-[1rem]">
        <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
          <h3 className="text-[22px] font-semibold">All Reviews</h3>
          <div className="flex flex-row gap-[2rem]">
            {/* <Dropdown
            options={options}
            onChange={handleSelect}
            value={selectedOption}
          /> */}

            {/* {showModal && modal} */}
          </div>
        </div>
        {/* <Table data={data?.data} config={config} keyFn={keyFn} /> */}
      </div>
      <div className="adminorderpage_table_table">
        <table>
          <thead>
            <tr>
              <th>S/L</th>
              <th style={{ width: "250px" }}>UserName</th>
              <th>UserMessage</th>
              <th>Published</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allReviews &&
              allReviews
                ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                ?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td style={{ width: "250px" }}>
                      <p>{item?.name}</p>
                    </td>
                    <td style={{ height: "100px", overflowY: "hidden" }}>
                      <p>{item?.message}</p>
                    </td>
                    <td>
                      <label
                        className="switch"
                        onClick={() =>
                          getEditReviewHandle(item?.id, item?.published)
                        }
                      >
                        <input
                          type="checkbox"
                          checked={item?.published === 1 ? true : false}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td>
                      <Visibility
                        style={{ color: "grey" }}
                        onClick={() => [
                          handleOpen(),
                          filterReviewHandle(item?.id),
                        ]}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className="pagination flex items-center justify-center">
        <BasicPagination count={count?count:1} setPage={setPage} />
      </div>

      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style1}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              User Name
            </Typography>
            <span style={{ width: "100%" }}>
              <input
                disabled
                type="text"
                value={UserName}
                placeholder="User Name"
                style={{
                  width: "100%",
                  height: "50px",
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  outline: "none",
                  paddingLeft: "5px",
                  paddingTop: "10px",
                }}
              />
            </span>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              User Message
            </Typography>
            <textarea
              disabled
              value={singeMessage}
              rows={5}
              placeholder="user message"
              style={{
                width: "100%",
                border: "1px solid lightgray",
                borderRadius: "5px",
                outline: "none",
                paddingLeft: "5px",
                paddingTop: "10px",
              }}
            />
          </Box>
        </Fade>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default AllReviews;
