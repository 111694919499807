import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  doctorData: [],
  loading:false,
  selectedDoctor:[]
};
export const allDoctor = createAsyncThunk("allDoctor", async () => {
  const { data } = await axios.get(
    `${process.env.React_App_Base_Url + "Get-all-Doctors"}`
  );
  return data;
});
export const doctor = createSlice({
  name: "doctorSlice",
  initialState,
  reducers: {
    addSelectedDoctor:(state,action)=>{
      state.selectedDoctor=(action.payload)
    },
    removeSelectedDoctor:(state)=>{
      state.selectedDoctor=[]
    }

  },
  extraReducers: {
    [allDoctor.pending]: (state) => {
        state.loading = true;
      },
       [allDoctor.rejected]: (state) => {
        state.loading = false;
      },
    [allDoctor.fulfilled]: (state, action) => {
      state.doctorData = action.payload;
      state.loading = false;
    },
  },
});
export const { addSelectedDoctor, removeSelectedDoctor } = doctor.actions;
export default doctor.reducer;
