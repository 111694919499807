import React from "react";
import "./ClientTestimonialCard.css";
function ClientTestimonialCard() {
  return (
    <div className="clienttestimonial">
      <p>
        My first booking experience with Caspian was simple and hassle-free.
        Selected sample collection slot on their app and the technician arrived
        right on time in the morning. Very impressive
      </p>
      <h6>Lorem</h6>
    </div>
  );
}

export default ClientTestimonialCard;
