import React from "react";
import "./DoctorCard.css";
import img from "../../../img/media.jpg";
import img1 from "../../../img/Ellipse 34.jpg";
import {
  Bookmark,
  HealthAndSafety,
  Language,
  LocationOn,
  Share,
  Star,
} from "@mui/icons-material";
import Button from "../../../Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openFormHandle } from "../../../Slice/userLogin";
import {
  FacebookShareCount,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

function DoctorCard({
  firstName,
  lastName,
  main_specialization,
  secondary_specialization,
  profile_image,
  experience,
  doctorId,
  zipCode,
  address,
}) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { userLogin } = useSelector((state) => state.userlogin);
  console.log(userLogin, "userlogin");
  return (
    <div className="doctorcard">
      <div className="doctorcard_first">
        <img src={img} alt="doctor_hospital" loading="lazy" />
      </div>
      <div className="doctorcard_second">
        <div className="doctorcard_second_left">
          <img
            src={process.env.React_App_Base_Image_Url + profile_image}
            alt="doctor_profile_pic"
            loading="lazy"
          />
          <span>
            <p> 100% Trusted</p>
            <HealthAndSafety />
          </span>
        </div>
        <div className="doctorcard_second_right">
          <div>
            <p>
              Dr {firstName} {lastName}
            </p>
            {/* <span>
              <p>4.9</p>
              <Star className="doctorcard_second_right_icon"/>
            </span> */}
          </div>
          <div className="doctorcard_second_right_details">
            <p>
              {main_specialization} / {secondary_specialization}
            </p>
          </div>
          <div>
            <p>{experience} years Exp</p>
          </div>
          <div>
            <p>Fees</p>
            <strong>₹500</strong>
          </div>
        </div>
      </div>
      <div className="doctorcard_third">
        <div>
          <span>
            <LocationOn style={{ color: "#DC3545" }} />
          </span>
          <span>
            <p>
              {address} {zipCode}
            </p>
          </span>
        </div>
      </div>
      <div className="doctorcard_fourth">
        {userLogin ? (
          <span onClick={() => history(`/doctorcheckout/${doctorId}`)}>
            <Button bg="#6C98FF" color="#fff">
              Book An Appointment
            </Button>
          </span>
        ) : (
          <span onClick={() => dispatch(openFormHandle())}>
            <Button bg="#6C98FF" color="#fff">
              Book An Appointment
            </Button>
          </span>
        )}

        <WhatsappShareButton
          url={`${'https://caspianhealthcare.com/doctorcheckout/'+doctorId}`}
        >
          <WhatsappIcon
            style={{ width: "40px", height: "40px", borderRadius: "5px" }}
          />
        </WhatsappShareButton>

        {/* <Share className="doctorcard_fourth_icon" /> */}
      </div>
    </div>
  );
}

export default DoctorCard;
