import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  subCategoryData: [],

  loading:false
};
export const allSubCategory = createAsyncThunk("allSubCategory", async () => {
  const { data } = await axios.get(
    `${process.env.React_App_Base_Url + "Get-all-Sub-Category"}`
    
  );

  return data;
});
export const subCategory = createSlice({
  name: "subCategory",
  initialState,
  reducers: {},
  extraReducers: {
    [allSubCategory.pending]: (state, action) => {
        state.loading = true;
      },
       [allSubCategory.rejected]: (state, action) => {
        state.loading = false;
      },
    [allSubCategory.fulfilled]: (state, action) => {
      state.subCategoryData = action.payload;
      state.loading = false;
    },
  
      
  },
});
export default subCategory.reducer;
