import { DeleteOutline } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./CartCard.css";
import img from "../img/image 4.jpg";
import { useStateValue } from "../context api/StateProvider";
import Quantity from "../Quantity/Quantity";
import axios from "axios";
import { useDispatch } from "react-redux";
import { cartAllData } from "../Slice/cartSlice";
import Cookies from "js-cookie";
function Cartcard({
  id,
  setQuantity1,
  productimg,
  price,
  discription,
  discount,
  image,
  product_name,
  number_of_item,
  total_price,
  product_id,
  shortDescription,
  setIsLoading,
  isLoading,
}) {
  const [removeLoading,setRemoveLoading]=useState(false)
  const [quantity, setQuantity] = useState(Number(number_of_item));
  // const [{ cart }, dispatch] = useStateValue(1);
 
  const [amount, setAmount] = useState(number_of_item);
 
  const userId=Cookies.get('userId')
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(false);
  const removeFromCartHandle = async () => {
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "Delete-carts/" + id}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setRemoveLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setRemoveLoading(false));
    dispatch(cartAllData());

    console.log(data);
  };
  const productQuantityHandle=async()=>{
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("product_id",product_id );
    formData.append("number_of_item", quantity);
    formData.append("per_item_price", total_price);
    formData.append("total_price",total_price );
    const  data  = await axios
      .post(`${process.env.React_App_Base_Url+'Update-carts/'+id}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response)
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
      if(data){
        dispatch(cartAllData())
      }

    console.log(data);
  }
  const handleDecrese = () => {
    quantity > 1 ? setQuantity(quantity - 1) : setQuantity(quantity);
  };
  const handleIncrease = () => {
    setQuantity(quantity + 1);
    
  };
  useEffect(()=>{
    productQuantityHandle()
  },[quantity])
  return (
    <div className="cartcard">
      {/* <div className="cartcard_img">
       
      </div> */}
      <div className="cartcard_details">
        <img src={image ? process.env.React_App_Base_Image_Url + image : img} />
        <div>
          <p>{product_name ? product_name : "No Title Found"}</p>
          {/* <br/> */}
          <p style={{ fontSize: "14px", color: "grey", marginTop: "5px",overflowY:'hidden',height:'60px' }}>
            {shortDescription ? shortDescription : ""}
          </p>
        </div>

        <span>
          <strong>₹{total_price ? total_price : "NaN"}</strong>
          <s>{discount ? discount : 3000}</s>
        </span>
      </div>
      <div className="cartcard_quantity">
        {!removeLoading ? (
          <p onClick={removeFromCartHandle}>
            <DeleteOutline /> Remove{" "}
          </p>
        ) : (
          <p>
            <DeleteOutline /> Removing...
          </p>
        )}
        <Quantity
          amount={quantity}
          handleDecrese={handleDecrese}
          handleIncrease={handleIncrease}
        />
      </div>
    </div>
  );
}

export default Cartcard;
