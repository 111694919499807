import React, { useState } from "react";
import "./ContactUsPage.css";

import Navbar from "../../../Components/Navbar/Navbar";
import SubNav from "../../../Components/SubNav/SubNav";
import Footer from "../../../Footer/Footer";

import BannerImg from "../../../img/Fahad/Rectangle 110529.png";

import { BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

import mapImage from "../../../img/Fahad/image 95.png";

import LargestHealthCard from "../../../LargestHealthCard/LargestHealthCard";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

export default function ContactUsPage() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const notify = () => toast.success("Successfully Submitted!");
  const notify1 = () => toast.warning("Something Went Wrong!");

  const [isLoading, setIsLoading] = useState();

  const sendContactUsHandle = async (data) => {
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("mobile", data?.mobile);
    formData.append("email", data?.email);
    formData.append("message", data?.message);
    const response = await axios
      .post(
        `${process.env.React_App_Base_Url + "Contaus-us-create"}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
      if (data) {
        notify();
        reset({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
      }
      if (!data) {
        notify1();
      }

    console.log(response);
  };
  return (
    <div className="contactus_page-section">
      <SubNav />
      <Navbar />
      <div className="contactus_page-main">
        <h3 className="contactus_page-heading p-[2rem] text-center text-[22px]">
          Contact Us
        </h3>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            src={BannerImg}
            alt="bannerImg"
            style={{ height: "350px", objectFit: "cover", width: "90%" }}
          />
        </div>

        <div className="contactus_page flex w-full flex-row ">
          <div className="contactus_page-left flex w-[70%] flex-col gap-[1rem] p-[2rem] relative">
            <form onSubmit={handleSubmit(sendContactUsHandle)}>
              <input
                className="mb-5 w-full rounded-[8px] border-2 border-solid border-[#efefef] p-[1rem] outline-0"
                placeholder="Name"
                type="text"
                {...register("name", {
                  required: true,
                  validate: (value) => {
                    return !!value.trim();
                  },
                })}
              />
              {errors.name && (
                <p className="loginFormError">Please Enter Valid Name</p>
              )}
              <input
                className="mb-5 w-full rounded-[8px] border-2 border-solid border-[#efefef] p-[1rem] outline-0"
                placeholder="Mobile"
                type="number"
                {...register("mobile", {
                  required: true,
                  pattern: /^[0-9+-]+$/,
                  minLength: 10,
                  maxLength: 12,
                })}
              />
              {errors.mobile && (
                <p className="loginFormError">Please Enter Valid Number</p>
              )}

              <input
                className="mb-5 w-full rounded-[8px] border-2 border-solid border-[#efefef] p-[1rem] outline-0"
                placeholder="Email"
                type="email"
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.email && (
                <p className="loginFormError">Please Enter Valid Email</p>
              )}
              <textarea
                className="mb-5 w-full rounded-[8px] border-2 border-solid border-[#efefef] p-[1rem] outline-0"
                placeholder="Message"
                type="text"
                rows={4}
                {...register("message", {
                  required: true,
                  validate: (value) => {
                    return !!value.trim();
                  },
                })}
              />
              {errors.message && (
                <p className="loginFormError">Please Enter Valid Message</p>
              )}
              {!isLoading && (
                <button
                  type="submit"
                  style={{
                    borderRadius: "6px",
                    background: "#2A7FBA",
                    padding: "10px 15px",
                    color: "#fff",
                    fontSize: "18px",
                  }}
                >
                  Submit
                </button>
              )}
             
            </form>
            {isLoading && (
                <Box sx={style}>
                  <CircularProgress />
                </Box>
              )}
          </div>
          <div className="contactus_page-right flex w-[30%] flex-row items-center">
            <div className="contactus_page-right-helpline-card flex w-full flex-col items-center gap-[1rem] bg-[#F5F5F5] p-[1rem]">
              <p className="contactus_page-right-helpline-card-heading text-[22px] font-[500]">
                Helpline Details
              </p>
              <div className="contactus_page-right-helpline-card-content flex w-full flex-row justify-between bg-white p-[1rem]">
                <BsTelephoneFill className="text-[30px]" />
                <p>+91-1234567890</p>
              </div>
              <div className="contactus_page-right-helpline-card-content flex w-full flex-row justify-between bg-white p-[1rem]">
                <GrMail className="text-[30px]" />
                <p>exampleemail@email.com</p>
              </div>
              <div className="contactus_page-right-helpline-card-content flex w-full flex-row justify-between bg-white p-[1rem]">
                <GrMail className="text-[30px]" />
                <p>exampleemail@email.com</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'conter'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.3631089081478!2d78.44675707721909!3d17.394353460069237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9708777b5381%3A0x780594cd924f1c46!2sCaspian%20Healthcare!5e0!3m2!1sen!2sin!4v1702360253026!5m2!1sen!2sin" style={{width:'100%',height:'550px'}}  allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        {/* <img className="p-[2rem]" src={mapImage} alt="mapImage" /> */}
        <LargestHealthCard />
        {/* <div className='contactus_page-lowersection flex flex-col items-center gap-[1rem]'>
          <p className='text-[20px] tracking-wide'>
            INDIA’S LARGEST HEALTHCARE PLATFORM
          </p>
          <div className='contactus_page-lowersection-cards'>
            <div className='flex flex-col items-center gap-[5px] rounded-[8px] bg-[#28A745] p-[3rem] text-white'>
              <p className='text-[20px]'>60k+</p>
              <p className='text-[20px]'>Visitors</p>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
