import React, { useEffect } from "react";
// import { BsEyeFill } from 'react-icons/bs';
import AppearancePage from "./AppearancePage";
import { useState } from "react";
// import Switch from 'react-switch';
import SwitchButton from "../../components/SwitchButton";
import Button from "../../components/Button";
// import DialogBox from '../../components/DIalogBox/DialogBox';
// import Modal from "../../components/Modal";
// import DragDropComponent from '../../components/DragDropComponent/DragDropComponent';

import { useContext } from "react";
import DataContext from "../../../context api/StateProvider";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";

export default function Header() {
  // const [isChecked, setIsChecked] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 550,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
  };
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [headerId, setHeaderId] = useState();
  const [headerTitle, setHeaderTitle] = useState();
  const [headerLink, setHeaderLink] = useState();
  const [headerImage, setHeaderImage] = useState();
  const [allHeader, setAllHeader] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const notify = () => toast.success("Successfully Submitted!");
  const notify1 = () => toast.warning("Something Went Wrong!");
  const allHeaderHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-all-header"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllHeader(data?.Headers && data?.Headers);

    console.log(data, "all data");
  };
  const addNewHeaderHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", headerTitle);
    formData.append("link", headerLink);
    formData.append("image", headerImage);
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "Create-header"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true), handleClose())
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data) {
      notify();
    }
    if (!data) {
      notify1();
    }
    allHeaderHandle();

    console.log(data);
  };
  const editHeaderHandle = async (id) => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-One-header/" + id}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setHeaderTitle(data && data?.Headers?.title);
    setHeaderLink(data && data?.Headers?.link);
    setHeaderImage(data && data?.Headers?.image);
    console.log(data, "single");
  };
  const updateHeaderHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("blogTitle");
    formData.append("blogIntroduction");
    formData.append("blogText");
    formData.append("blogImage");
    const data = await axios
      .put(`${process.env.React_App_Base_Url + "Get-all-header"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data) {
      notify();
    }
    if (!data) {
      notify1();
    }
    allHeaderHandle();
    console.log(data);
  };
  const deleteHeaderHandle = async () => {
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "Delete-header/" + headerId}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true), handleClose2())
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    if (data) {
      notify();
    }
    if (!data) {
      notify1();
    }
    allHeaderHandle();
  };
  const publishHeaderHandle = async (id) => {
    const { data } = await axios
      .post(`${process.env.React_App_Base_Url + "publish-header/" + id}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    console.log(data);
    allHeaderHandle()
  };
  useEffect(() => {
    allHeaderHandle();
  }, []);

  const { header, editHeaderById } = useContext(DataContext);

  const [editUpperNavLinkTitle, setEditUpperNavLinkTitle] = useState(
    header.upperHeaderLinkName
  );
  const [editUpperNavLinkImage, setEditUpperNavLinkImage] = useState(
    header.upperHeaderLinkImage
  );

  const [editId, setEditId] = useState(null);
  const handleEditSubmit = (e) => {
    e.preventDefault();
    editHeaderById(editId, editUpperNavLinkTitle, editUpperNavLinkImage);
  };
  console.log(editId);

  const updateProductModalStyle = {
    maxHeight: "calc(100vh - 10rem)",
    overflowY: "auto",
  };

  ////////////Modal - Update Product
  const [showModal, setShowModal] = useState(false);

  const formContent = (
    <div className="" style={updateProductModalStyle}>
      <h2 className="border-b p-[1rem] text-[22px] font-semibold">
        Topbar Links
      </h2>
      <form action="" className="flex flex-col gap-[1rem] p-[1rem]">
        <label htmlFor="">Name</label>
        <input
          value={editUpperNavLinkTitle}
          className="border p-[1rem]"
          type="text"
          placeholder="Enter Name"
          onChange={(event) => setEditUpperNavLinkTitle(event.target.value)}
        />

        <label htmlFor="">Image</label>
        <input
          type="file"
          onChange={(event) => setEditUpperNavLinkImage(event.target.files[0])}
        />
        {/* <div className="flex flex-row items-center justify-center border-[1px] border-dashed border-[#1f1f1f] px-[3rem] py-[2rem]">
          <label
            htmlFor="imgUpload"
            className="border-[1px] border-dashed border-[#1f1f1f] px-[2rem] py-[1rem] text-[20px] font-semibold"
          >
            +
          </label>
          <input
            className="hidden"
            type="file"
            id="imgUpload"
            name="img"
            placeholder="Upload An Image..."
          />
        </div> */}

        {/* <div className="flex flex-row items-center justify-center border-[1px] border-dashed border-[#1f1f1f] px-[3rem] py-[2rem]">
          <label
            htmlFor="imgUpload"
            className="border-[1px] border-dashed border-[#1f1f1f] px-[2rem] py-[1rem] text-[20px] font-semibold"
          >
            +
          </label>
          <input
            className="hidden"
            type="file"
            id="imgUpload"
            name="img"
            placeholder="Upload An Image..."
          />
        </div> */}
        <Button
          onClick={handleEditSubmit}
          className="w-fit rounded-[8px] bg-green-500 hover:bg-green-600"
        >
          Save Now
        </Button>
      </form>
    </div>
  );

  const handleClick = (id) => {
    setShowModal(true);
    setEditId(id);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const actionBar = (
    <div>
      <Button onClick={closeModal} primary>
        Close
      </Button>
    </div>
  );

  // const modal = (
  //   <Modal
  //     modalHeading={"Navbar Categories"}
  //     onClose={closeModal}
  //     actionBar={actionBar}
  //   >
  //     {formContent}
  //   </Modal>
  // );
  // Modal End

  //Form 2
  // const formContent1 = (
  //   <div className="border">
  //     <h2 className="border-b p-[1rem] text-[22px] font-semibold">
  //       Add New Slider
  //     </h2>
  //     <form action="" className="flex flex-col gap-[1rem] p-[1rem]">
  //       <label htmlFor="">Upload Brand Image</label>
  //       <div className="flex flex-row items-center justify-center border-[1px] border-dashed border-[#1f1f1f] px-[3rem] py-[2rem]">
  //         <label
  //           htmlFor="imgUpload"
  //           className="border-[1px] border-dashed border-[#1f1f1f] px-[2rem] py-[1rem] text-[20px] font-semibold"
  //         >
  //           +
  //         </label>
  //         <input
  //           className="hidden"
  //           type="file"
  //           id="imgUpload"
  //           name="img"
  //           placeholder="Upload An Image..."
  //         />
  //       </div>
  //       <Button className="w-fit rounded-[8px] bg-green-500 hover:bg-green-600">
  //         Save Now
  //       </Button>
  //     </form>
  //   </div>
  // );
  //Table
  // /----------Dialog
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpenDialog = (id) => {
  //   setOpen({ open: true, id: id });
  // };
  const data = header;

  const config = [
    {
      label: "S/N",
      render: (list) => list.id,
      sortValue: (list) => list.id,
    },
    {
      label: "Name",
      render: (list) => list.upperHeaderLinkName,
      sortValue: (list) => list.upperHeaderLinkName,
    },
    {
      label: "Image",
      render: (list) => (
        <img src={list.upperHeaderLinkImage} alt={list.upperHeaderLinkName} />
      ),
    },
    {
      label: "Published",
      render: (list) => <SwitchButton />,
      sortValue: (list) => <SwitchButton />,
    },
    {
      label: "Action",
      render: (list) => (
        <div className="flex flex-col items-center justify-center gap-[10px]">
          <Button
            onClick={() => handleClick(list.id)}
            primary
            className="flex w-[100px] justify-center text-center"
          >
            Edit
          </Button>
          {/* <Button
            danger
            className="flex w-[100px] justify-center text-center"
            onClick={() => handleClickOpenDialog(list.id)}
          >
            Delete
          </Button> */}
        </div>
      ),
      sortValue: (list) => list.price,
    },
  ];

  const keyFn = (list) => {
    return list.id;
  };
  return (
    <div>
      <div className="products-page flex flex-col gap-[2rem] p-[1rem]">
        <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
          <h3 className="text-[22px] font-semibold">Header</h3>
          <div className="flex flex-row gap-[2rem]">
            {/* <Dropdown
            options={options}
            onChange={handleSelect}
            value={selectedOption}
          /> */}
            <Button
              onClick={handleOpen}
              className="flex h-[3.5rem] w-48 flex-row items-center justify-center gap-[4px] rounded-[0.25rem] bg-green-500 text-white shadow hover:bg-green-600"
            >
              <p>Add Header</p>
            </Button>
            {/* {showModal && modal} */}
          </div>
        </div>
        {/* <Table data={data?.data} config={config} keyFn={keyFn} /> */}
      </div>

      {/* <div className="p-[1rem]">{formContent1}</div> */}
      {/* <DialogBox open={open} setOpen={setOpen} data={deleteHeaderById} /> */}
      {/* {showModal && modal} */}
      <div className="adminorderpage_table_table">
        <table>
          <thead>
            <tr>
              <th>S/L</th>
              <th style={{ width: "250px" }}>Header Img</th>
              <th>Header Title</th>
              <th>Header Link</th>
              <th>Published</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allHeader?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td style={{ width: "250px" }}>
                  <img
                    src={process.env.React_App_Base_Image_Url + item?.image}
                    alt="Header"
                    style={{ width1: "100px", height: "50px" }}
                  />
                </td>
                <td>{item?.title}</td>
                <td>{item?.link}</td>
                <td>
                  <label className="switch" >
                    <input type="checkbox" checked={item?.published===1?true:false} onClick={()=>publishHeaderHandle(item?.id)}/>
                    <span className="slider round"></span>
                  </label>
                </td>
                <td>
                  <Edit
                    style={{
                      color: "#6E798C",
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => [handleOpen1(), editHeaderHandle(item?.id)]}
                  />
                  <Delete
                    style={{
                      color: "#6E798C",
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpen2(setHeaderId(item?.id))}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={addNewHeaderHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add New Header
            </Typography>
            <p className="modal_form_para">New Header Title</p>
            <span>
              <input
                type="text"
                placeholder="New Header Title"
                onChange={(e) => setHeaderTitle(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Header Link</p>
            <span>
              <input
                type="text"
                placeholder="Header Link"
                onChange={(e) => setHeaderLink(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Header Image</p>

            <input
              type="file"
              onChange={(e) => setHeaderImage(e.target.files[0])}
              required
            />

            <button className="modal_form_buttom">Add New Header</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={updateHeaderHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Update Header
            </Typography>
            <p className="modal_form_para">Update Header Title</p>
            <span>
              <input
                type="text"
                placeholder="New Header Title"
                value={headerTitle}
                onChange={(e) => setHeaderTitle(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Header Link</p>
            <span>
              <input
                type="text"
                placeholder="Header Link"
                value={headerLink}
                onChange={(e) => setHeaderLink(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Header Image</p>

            <input
              type="file"
              onChange={(e) => setHeaderImage(e.target.files[0])}
              required
            />

            <button className="modal_form_buttom">Update Header</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You Sure ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <button className="button-cancel" onClick={handleClose2}>
              Cancel
            </button>
            <button className="button-proceed" onClick={deleteHeaderHandle}>
              Proceed
            </button>
          </Typography>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
