import React from "react";
import "./TestInformationCard.css";
function TestInformationCard() {
  return (
    <div className="testinformationcard">
      <div className="testinformationcard_heading">
        <h6>Test Information</h6>
      </div>
      <div className="testinformationcard_details">
        <p>
          Lorem ipsum dolor sit amet consectetur. Scelerisque facilisis vitae
          pellentesque sed. Enim eu egestas in bibendum amet. Iaculis porttitor
          nulla penatibus nulla id proin velit elementum. Sed a sagittis odio
          interdum tortor nascetur dolor lobortis. Leo tincidunt sed
          pellentesque sed id sed lacus sed. Condimentum malesuada faucibus hac
          velit laoreet sed ultrices. Amet commodo vel ipsum mauris montes id
          aliquet pulvinar. Vel arcu risus volutpat varius suspendisse nunc
          netus. Dui ut eget ultrices consectetur ut. Eu fringilla sagittis
          neque suspendisse elementum pharetra fermentum. Turpis eget eu lorem
          vel egestas.
        </p>
      </div>
    </div>
  );
}

export default TestInformationCard;
