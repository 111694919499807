import React from 'react'
import './ErrorPage.css'
import { useNavigate } from 'react-router-dom'
function ErrorPage() {
    const history=useNavigate()
  return (
    <div className='ErrorPage'>
        <h3>404</h3>
        <p>Oops! Page not found</p>
        <button onClick={()=>history('/')}>Back to Homepage</button>
    </div>
  )
}

export default ErrorPage