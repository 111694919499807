import React, { useEffect } from "react";
import DoctorCards from "../../components/DoctorsPageCards/DoctorCards";
import Button from "../../components/Button";
// import Modal from '../../components/Modal';
import { useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default function DoctorsPage({
  addBtn,
  modalHeading,
  modalContent,
  name,
  showBtn,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 550,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: "0",
    borderRadius: "5px",
    overflowY: "scroll",
  };
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  //Modal
  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const actionBar = (
    <div>
      <Button onClick={closeModal} primary>
        Close
      </Button>
    </div>
  );

  // const modal = (
  //   <Modal
  //     modalHeading={modalHeading}
  //     onClose={closeModal}
  //     actionBar={actionBar}
  //   >
  //     <p>{modalContent}</p>
  //   </Modal>
  // );
  const notify = () => toast.success("Successfully Submitted!");
  const notify1 = () => toast.warning("Something Went Wrong!");

  const [isLoading, setIsLoading] = useState();
  const [allDoctor, setAllDoctor] = useState();
  const [doctorId, setDoctorId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const [dob, setDob] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobile] = useState();
  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [bio, setBio] = useState();
  const [doctorRole, setDoctorRole] = useState();
  const [mainSpecialization, setMainSpecialization] = useState();
  const [secondarySpecialization, setSecondarySpecialization] = useState();
  const [medicalEducation, setMedicalEducation] = useState();
  const [yearsOfExperience, setyearsOfExperience] = useState();
  const [doctorProfile, setDoctorProfile] = useState();
  const allDoctorHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-all-Doctors"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    console.log(data);

    setAllDoctor(data?.doctors && data?.doctors);
  };
  const addDoctorHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("number", mobileNumber);
    formData.append("dob", dob);
    formData.append("gender", gender);
    formData.append("zip_code", zipCode);
    formData.append("city", city);
    formData.append("doctor_role", doctorRole);
    formData.append("address", fullAddress);
    formData.append("bio", bio);
    formData.append("main_specialization", mainSpecialization);
    formData.append("secondary_specialization", secondarySpecialization);
    formData.append("medical_education", medicalEducation);
    formData.append("experience", yearsOfExperience);
    formData.append("profile_image", doctorProfile);
    const data = await axios
      .post(`${process.env.React_App_Base_Url + "Create-Doctors"}`, formData, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true), handleClose())
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data?.status === 201) {
      notify();
    }
    if (!data) {
      notify1();
    }
    console.log(data);
    allDoctorHandle()
  };
  const editDoctorHandle = async (id) => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-One-Doctors/" + id}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    setDoctorId(data?.Doctors && data?.Doctors?.id);
    setFirstName(data?.Doctors && data?.Doctors?.first_name);
    setLastName(data?.Doctors && data?.Doctors?.last_name);
    setEmail(data?.Doctors && data?.Doctors?.email);
    setFullAddress(data?.Doctors && data?.Doctors?.address);
    setBio(data?.Doctors && data?.Doctors?.bio);
    setCity(data?.Doctors && data?.Doctors?.city);
    setDob(data?.Doctors && data?.Doctors?.dob);
    setDoctorRole(data?.Doctors && data?.Doctors?.doctor_role);
    setyearsOfExperience(data?.Doctors && data?.Doctors?.experience);
    setMainSpecialization(data?.Doctors && data?.Doctors?.main_specialization);
    setMedicalEducation(data?.Doctors && data?.Doctors?.medical_education);
    setMobile(data?.Doctors && data?.Doctors?.number);
    setSecondarySpecialization(
      data?.Doctors && data?.Doctors?.secondary_specialization
    );
    setDoctorProfile(data?.Doctors && data?.Doctors?.profile_image);
    setMobile(data?.Doctors && data?.Doctors?.number);
    setZipCode(data?.Doctors && data?.Doctors?.zip_code);
    console.log(data?.Doctors, "single blog");
  };
  const updateDoctorHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("number", mobileNumber);
    formData.append("dob", dob);
    formData.append("gender", gender);
    formData.append("zip_code", zipCode);
    formData.append("city", city);
    formData.append("doctor_role", doctorRole);
    formData.append("address", fullAddress);
    formData.append("bio", bio);
    formData.append("main_specialization", mainSpecialization);
    formData.append("secondary_specialization", secondarySpecialization);
    formData.append("medical_education", medicalEducation);
    formData.append("experience", yearsOfExperience);
    formData.append("profile_image", doctorProfile);
    const { data } = await axios
      .post(
        `${process.env.React_App_Base_Url + "Update-Doctors/" + doctorId}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoading(true), handleClose1())
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
      allDoctorHandle()
    console.log(data);
  };

  useEffect(() => {
    allDoctorHandle();
  }, []);
  return (
    <div className="appearance-page flex flex-col gap-[2rem] p-[1rem]">
      <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
        <h3 className="text-[22px] font-semibold">All Docter</h3>
        <div className="flex flex-row gap-[2rem]">
          <Button
            onClick={handleOpen}
            className={`${showBtn} flex h-[3rem] w-48 flex-row items-center justify-center gap-[4px] rounded-[0.25rem] bg-green-500 text-white shadow hover:bg-green-600`}
          >
            <p>Add Doctor</p>
          </Button>
        </div>
      </div>
      <div className="adminorderpage_table_table">
        <table>
          <thead>
            <tr>
              <th>S/L</th>
              <th style={{ width: "250px" }}>Doctor Name</th>
              <th>Sex</th>
              <th>Main specialization</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allDoctor?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td style={{ width: "250px" }}>
                  <p>
                    {item?.first_name} {item?.last_name}
                  </p>
                </td>
                <td>{item?.gender}</td>
                <td>{item?.main_specialization}</td>
                <td>
                  <Edit
                    style={{
                      color: "#6E798C",
                      marginLeft: "5px",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                    // onClick={() => editSubCategoryHandle(item?.id)}
                    onClick={() => [handleOpen1(), editDoctorHandle(item?.id)]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={addDoctorHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Doctor
            </Typography>
            <p className="modal_form_para">Doctor Profile Pic</p>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setDoctorProfile(e.target.files[0])}
              required
            />
            <p className="modal_form_para">First Name</p>
            <span>
              <input
                type="text"
                placeholder="First Name"
                // onChange={(e) => setSubCategoryName(e.target.value)}
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
            </span>
            <p className="modal_form_para">Last Name</p>
            <span>
              <input
                type="text"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </span>

            <p className="modal_form_para">Gender</p>
            <span>
              <select onChange={(e) => setGender(e.target.value)} required>
                <option>Select One Option</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </span>
            <p className="modal_form_para">Date Of Birth</p>
            <span>
              <input
                type="date"
                placeholder="Date Of Birth"
                onChange={(e) => setDob(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Email</p>
            <span>
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Mobile Number</p>
            <span>
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">City</p>
            <span>
              <input
                type="text"
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Zip Code</p>
            <span>
              <input
                type="number"
                placeholder="Zip Code"
                onChange={(e) => setZipCode(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Full Address</p>
            <span>
              <textarea
                rows={5}
                type="text"
                placeholder="Full Address"
                onChange={(e) => setFullAddress(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Bio</p>
            <span>
              <textarea
                type="text"
                placeholder="Bio"
                onChange={(e) => setBio(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Doctor Role</p>
            <span>
              <input
                type="text"
                placeholder="Doctor Role"
                onChange={(e) => setDoctorRole(e.target.value)}
                required
              />
            </span>
            <h3 className="modal_form_para">Medical Information</h3>
            <p className="modal_form_para">Main specialization</p>
            <span>
              <input
                type="text"
                placeholder="Main Specialization"
                onChange={(e) => setMainSpecialization(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Secondary Specialization</p>
            <span>
              <input
                type="text"
                placeholder="Secondary specialization (optional)"
                onChange={(e) => setSecondarySpecialization(e.target.value)}
              />
            </span>
            <p className="modal_form_para">Medical Education</p>
            <span>
              <input
                type="text"
                placeholder="Medical Education"
                onChange={(e) => setMedicalEducation(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Years Of Experience</p>
            <span>
              <input
                type="number"
                placeholder="Years Of Experience"
                onChange={(e) => setyearsOfExperience(e.target.value)}
                required
              />
            </span>
            <button className="modal_form_buttom">Add Doctor</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style2}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <form className="modal_form" onSubmit={updateDoctorHandle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Edit Doctor Profile
            </Typography>
            <img
              src={process.env.React_App_Base_Image_Url + doctorProfile}
              alt="doctor profile"
              style={{
                height: "150px",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
            <p className="modal_form_para">Doctor Profile Pic</p>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setDoctorProfile(e.target.files[0])}
            
            />
            <p className="modal_form_para">First Name</p>
            <span>
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                // onChange={(e) => setSubCategoryName(e.target.value)}
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
            </span>
            <p className="modal_form_para">Last Name</p>
            <span>
              <input
                type="text"
                value={lastName}
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </span>

            <p className="modal_form_para">Gender</p>
            <span>
              <select onChange={(e) => setGender(e.target.value)} required>
                <option>Select One Option</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </span>
            <p className="modal_form_para">Date Of Birth</p>
            <span>
              <input
                type="date"
                value={dob}
                placeholder="Date Of Birth"
                onChange={(e) => setDob(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Email</p>
            <span>
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Mobile Number</p>
            <span>
              <input
                type="number"
                value={mobileNumber}
                placeholder="Mobile Number"
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">City</p>
            <span>
              <input
                type="text"
                value={city}
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Zip Code</p>
            <span>
              <input
                type="number"
                value={zipCode}
                placeholder="Zip Code"
                onChange={(e) => setZipCode(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Full Address</p>
            <span>
              <textarea
                rows={5}
                value={fullAddress}
                type="text"
                placeholder="Full Address"
                onChange={(e) => setFullAddress(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Bio</p>
            <span>
              <textarea
                type="text"
                value={bio}
                placeholder="Bio"
                onChange={(e) => setBio(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Doctor Role</p>
            <span>
              <input
                type="text"
                value={doctorRole}
                placeholder="Doctor Role"
                onChange={(e) => setDoctorRole(e.target.value)}
                required
              />
            </span>
            <h3 className="modal_form_para">Medical Information</h3>
            <p className="modal_form_para">Main specialization</p>
            <span>
              <input
                type="text"
                value={mainSpecialization}
                placeholder="Main Specialization"
                onChange={(e) => setMainSpecialization(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Secondary Specialization</p>
            <span>
              <input
                type="text"
                value={secondarySpecialization}
                placeholder="Secondary specialization (optional)"
                onChange={(e) => setSecondarySpecialization(e.target.value)}
              />
            </span>
            <p className="modal_form_para">Medical Education</p>
            <span>
              <input
                type="text"
                value={medicalEducation}
                placeholder="Medical Education"
                onChange={(e) => setMedicalEducation(e.target.value)}
                required
              />
            </span>
            <p className="modal_form_para">Years Of Experience</p>
            <span>
              <input
                type="number"
                value={yearsOfExperience}
                placeholder="Years Of Experience"
                onChange={(e) => setyearsOfExperience(e.target.value)}
                required
              />
            </span>
            <button className="modal_form_buttom">Add Doctor</button>
          </form>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
