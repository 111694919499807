import React from "react";
import "./OrderConfirm.css";
import ThankYou from "../img/icon.png";
function OrderConfirm() {
  return (
    <div className="orderconfirm">
      <div className="orderconfirm_top">
        <img src={ThankYou} alt="" />
        <p style={{ color: " #2A7FBA", fontSize: "25px" }}>
          Your Order is Confirmed!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur. Varius aliquet eu at fermentum
          sapien sed semper id urna. Donec nulla nibh lorem morbi enim ut in.
          Adipiscing in sem tellus et ut pharetra. Duis eget morbi scelerisque
          tellus
        </p>
      </div>
      <div className="orderconfirm_bottom">
        <div>
          <span style={{ display: "flex" }}>
            <p>Order Id : </p>
            <p> #XXXX XXXX XXXX 4520</p>
          </span>
          <span style={{ display: "flex" }}>
            <p>Order Date : </p>
            <p> 12/02/2010</p>
          </span>
        </div>

        <div className="orderconfirm_bottom_subtotal">
          <span>
            <p>SubTotal:</p>
            <strong>1264</strong>
          </span>
          <span>
            <p>Shipping:</p>
            <strong>40</strong>
          </span>
          <span>
            <p>Total:</p>
            <strong>1304</strong>
          </span>
        </div>
        {/* <span>
            <span>
                <p>sub-total</p>
                <p>1450</p>
            </span>
            <span>
                <p>+ tax </p>
                <p>255</p>
            </span>
             <span>
                <p> total </p>
                <p>1705</p>
            </span>
        </span> */}
      </div>
    </div>
  );
}

export default OrderConfirm;
