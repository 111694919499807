import React, { useEffect, useState } from "react";
import TableWithDropdown from "../../components/Table/TableWithDropdown";
import axios from "axios";

export default function Support() {
  const [isLoading, setIsLoading] = useState();
  const [allMessage, setAllMessage] = useState();
  const getAllContactUsHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Contaus-us-all-data"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setAllMessage(data && data[0]);
    console.log(data[0]);
  };
  useEffect(() => {
    getAllContactUsHandle();
  }, []);
  const tableHeads = ["Id", "Name", "Email", "Phone", "Issue"];
  const tableData = [
    {
      id: 1,
      name: "Dr. Arman",
      email: "example@example.com",
      phone: "1234567890",
      issue: "Repair",

      message: [
        {
          date: "2020-01-05",
          customerId: "11091700",
          msg: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur debitis obcaecati commodi dolorum impedit est quaerat asperiores nobis ducimus sint facilis voluptas eos reiciendis iusto, maiores odio, temporibus animi pariatur?",
        },
        {
          date: "2020-01-02",
          customerId: "Anonymous",
          msg: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur debitis obcaecati commodi dolorum impedit est quaerat asperiores nobis ducimus sint facilis voluptas eos reiciendis iusto, maiores odio, temporibus animi pariatur?",
        },
      ],
    },
    {
      id: 2,
      name: "Dr. Aquib",
      email: "example@example.com",
      phone: "1234567890",
      issue: "Repair",

      message: [
        {
          date: "2020-01-05",
          customerId: "11091700",
          msg: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur debitis obcaecati commodi dolorum impedit est quaerat asperiores nobis ducimus sint facilis voluptas eos reiciendis iusto, maiores odio, temporibus animi pariatur?",
        },
        {
          date: "2020-01-02",
          customerId: "Anonymous",
          msg: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur debitis obcaecati commodi dolorum impedit est quaerat asperiores nobis ducimus sint facilis voluptas eos reiciendis iusto, maiores odio, temporibus animi pariatur?",
        },
      ],
    },
  ];
  return (
    <div className="products-page flex flex-col gap-[2rem] p-[1rem]">
      <div className="products-page-head flex flex-row items-center justify-between p-[1rem] shadow-lg">
        <h3 className="text-[22px] font-semibold">Support</h3>
      </div>
      <div className="adminorderpage_table_table">
        <table>
          <thead>
            <tr>
              <th style={{ width: "50px" }}>S/N</th>
              <th style={{ width: "150px" }}>User Name</th>
              <th style={{ width: "200px" }}>Email and Phone</th>
              <th >Message</th>
            </tr>
          </thead>
          <tbody>
            {allMessage?.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "50px" }}>{index + 1}</td>
                <td style={{ width: "150px" }}>{item?.name}</td>
                <td style={{ width: "200px" }}>
                  {item?.email}/{item?.mobile}
                </td>
                <td >{item?.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
