import React, { useEffect, useState } from "react";
import Button from "../../Button/Button";
import Cartcard from "../../Cart Card/Cartcard";
import { useStateValue } from "../../context api/StateProvider";
import CoupanCard from "../../Coupan Card/CoupanCard";
import HealthCheckOutCard from "../../HealthCheckOut Card/HealthCheckOutCard";
import SubTotalCard from "../../Sub Total Card/SubTotalCard";
import "./Cart.css";
import SubNav from "../SubNav/SubNav";
import Navbar from "../Navbar/Navbar";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
function Cart() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    zIndex: "11111",
  };
  const history = useNavigate();
  const { userLogin } = useSelector((state) => state.userlogin);
  const { cart, loading } = useSelector((state) => state?.cartData);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState();
  const getTotalHandle = () => {
    setTotal(
      cart?.reduce(function (sum, item, index) {
        const price = Number(item?.total_price);
        const quantity = Number(item?.number_of_item);
        // console.log(price*quantity)
        sum = price * quantity + sum;
        return sum;
      }, 0)
    );
  };
  useEffect(() => {
    getTotalHandle();
  }, [cart]);
  if (userLogin === false) {
    return (
      <>
        <SubNav />
        <Navbar />
        <div className="cart">
          <div className="cart_empty">
            <h6>Looks like there is no item in your cart yet. </h6>
            <Button bg="#2a7fba" color="#fff" link="/">
              Login Now
            </Button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <SubNav />
      <Navbar />
      <div className="cart">
        {cart?.length === 0 ? (
          <div className="cart_empty">
            <h6>Looks like there is no item in your cart yet. </h6>
            <Button bg="#2a7fba" color="#fff" link="/">
              Buy Medicine
            </Button>
          </div>
        ) : (
          <>
            <div className="cart_left">
              {cart?.map((item) => (
                <Cartcard
                  id={item?.cart_id}
                  setQuantity1={item?.quantity}
                  product_name={item?.product?.productName}
                  number_of_item={item?.number_of_item}
                  total_price={item?.per_item_price}
                  product_id={item?.product_id}
                  shortDescription={item?.product?.shortDescription}
                  image={item?.product?.images[0]?.image_path}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              ))}

              <CoupanCard />
            </div>
            <div className="cart_right">
              <HealthCheckOutCard />
              <SubTotalCard total={total} />
            </div>
          </>
        )}
        {loading && (
          <Box sx={style}>
            <CircularProgress />
          </Box>
        )}
        {isLoading && (
          <Box sx={style}>
            <CircularProgress />
          </Box>
        )}
      </div>
    </>
  );
}

export default Cart;
