import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  allheader: [],
  loading:false
};
export const getHeader = createAsyncThunk("getHeader", async () => {
  const { data } = await axios.get(
    `${process.env.React_App_Base_Url + "GenaralSetting-get-all"}`
  );
  return data[0];
});
export const header = createSlice({
  name: "header",
  initialState,
  reducers: {},
  extraReducers: {
    [getHeader.pending]: (state, action) => {
        state.loading = true;
      },
       [getHeader.rejected]: (state, action) => {
        state.loading = false;
      },
    [getHeader.fulfilled]: (state, action) => {
      state.allheader = action.payload;
      state.loading = false;
    },
  },
});
export default header.reducer;
