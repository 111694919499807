import { FilterAlt, Home, Logout, Store } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./MyProfile.css";
import img from ".././img/profilePic.webp";
import OrderCard from "../Order Card/OrderCard";
import SubNav from "../Components/SubNav/SubNav";
import Navbar from "../Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import { userLogout } from "../Slice/userLogin";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function MyProfile() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const [profilePage, setProfilePage] = useState(true);
  const [orderPage, setOrderPage] = useState(false);
  // const [profilePic, setProfilePic] = useState(true);
  const userId = Cookies.get("userId");
  const history = useNavigate();
  const userTokenCookies = Cookies.get("userToken");
  const notify1 = () => toast.warning("Something Went Wrong!");
  const notify2 = () => toast.success("Logout Successfully!");
  const notify3 = () => toast.success("Profile Update Successfully!");
  const myOrderPage = () => {
    setProfilePage(false);
    setOrderPage(true);
  };
  const myProfilePage = () => {
    setOrderPage(false);
    setProfilePage(true);
  };
  const { userLogin } = useSelector((state) => state.userlogin);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();
  const [profile, setProfile] = useState([]);
  const [profilePrev, setProfilePrev] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const getUserDataHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "get-one-user/" + userId}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    setName(data && data?.user?.name);
    setMobile(data && data?.user?.mobile_number);
    setEmail(data && data?.user?.email);
    // setProfile(data && data?.user?.profile_image);
    setProfilePrev(data && data?.user?.profile_image);

    console.log(data);
  };
  const userProfileUpdateHandle = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile_number", mobile);
    formData.append("password", password);
    formData.append("profile_image", profile);
    const data = await axios
      .post(
        `${process.env.React_App_Base_Url + "get-one-userDate/" + userId}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-date" },
        }
      )
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data) {
      notify3();
    }
    if (!data) {
      notify1();
    }
    console.log(data);
  };
  const userLogoutHandle = async () => {
    const formData = new FormData();

    const data = await axios
      .post(`${process.env.React_App_Base_Url + "logout-user"}`, formData, {
        headers: {
          Authorization: `${userTokenCookies}`,
          "Content-type": "multipart/form-date",
        },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    if (data?.status === 200) {
      notify2();
      history("/");
      Cookies.remove("userToken");
      dispatch(userLogout());
    }
    if (!data) {
      notify1();
    }
    console.log(data);
  };
  useEffect(() => {
    getUserDataHandle();
  }, []);

  if (!userLogin) {
    return (
      <>
        <SubNav />
        <Navbar />
        <div className="myprofile1">
          <p>You Need To Login First</p>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="myprofile">
        <div className="myprofile_left">
          <div className="myprofile_left_top">
            <span onClick={myProfilePage}>
              <Home /> <h6>Home</h6>
            </span>
            <span onClick={myOrderPage}>
              <Store /> <h6> Your Order</h6>{" "}
            </span>
          </div>
          <div className="myprofile_left_bottom">
            <span onClick={userLogoutHandle}>
              <Logout />
              <h6>LogOut</h6>
            </span>
          </div>
        </div>
        {profilePage && (
          <div className="myprofile_right">
            <div className="myprofile_right_heading">
              <h6> Account Setting</h6>
            </div>
            <div className="myprofile_right_profile">
              <p>Your Profile Picture</p>
              <div className="myprofile_right_profile_upload">
                <input
                  type="file"
                  alt="upload Profile Pic"
                  onChange={(e) => setProfile(e.target.files[0])}
                />

                <div className="uploaded_pic">
                  {profilePrev?.length > 0 ? (
                    <img
                      src={
                        profile?.length === 0
                          ? `${
                              process.env.React_App_Base_Image_Url + profilePrev
                            }`
                          : URL.createObjectURL(profile)
                      }
                      alt="user uploaded"
                    />
                  ) : (
                    <img
                      src={
                        profile?.length === 0
                          ? img
                          : URL.createObjectURL(profile)
                      }
                      alt="user uploaded"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="myprofile_right_form">
              <form
                className="myprofile_right_form_form"
                onSubmit={userProfileUpdateHandle}
              >
                <div className="myprofile_right_form_form_top">
                  <div>
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Enter Your Full name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="Enter Your Full name"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="myprofile_right_form_form_bottom">
                  <div>
                    <label>Password</label>
                    <input
                      type="text"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Phone number</label>
                    <input
                      type="number"
                      placeholder="Enter Your Full name"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="button_div">
                  <button className="button">Update Profile </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {orderPage && (
          <div className="myprofile_right">
            <div className="myprofile_right_heading">
              <h6> My Order</h6>
            </div>
            <div className="myprofile_right_order myprofile_right_order_filter">
              <p style={{ fontSize: "18px", fontWeight: "500" }}>Your Order</p>
              <FilterAlt
                style={{
                  color: "#FF9800",
                  fontSize: "35px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="myprofile_right_form myprofile_right_order">
              <OrderCard />
              <OrderCard />
              <OrderCard />
            </div>
          </div>
        )}
        {isLoading && (
          <Box sx={style}>
            <CircularProgress />
          </Box>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}

export default MyProfile;
