import React, { useEffect, useState } from "react";
import "./TermsAndCondition.css";

import SubNav from "../Components/SubNav/SubNav";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import HTMLReactParser from "html-react-parser";
import { Box, CircularProgress } from "@mui/material";

export default function TermsAndCondition() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.transparent",
    border: "2px solid transparent",
    boxShadow: 0,
    p: 4,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const [isLoading, setIsLoading] = useState();
  const [pageData, setPageData] = useState();
  const getPageHandle = async () => {
    const { data } = await axios
      .get(`${process.env.React_App_Base_Url + "Get-One-pages/2"}`, {
        headers: { "Content-type": "multipart/form-date" },
      })
      .then((response) => response, setIsLoading(true))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    console.log(data?.pages);
    setPageData(data && data?.pages);
  };
  useEffect(() => {
    getPageHandle();
  }, []);
  return (
    <div className="termsandconditions-Page">
      <SubNav />
      <Navbar />
      <div className="termsandconditions-page-main">
        <p className="p-[2rem] text-center text-[25px] font-[500]">
          {pageData && pageData?.title}
        </p>
        <div className="flex flex-col gap-[1rem] px-[2rem] pb-[2rem] " style={{minHeight:'50vh'}}>
          {HTMLReactParser(pageData ? pageData?.page_discription : "")}
        </div>
      </div>
      <Footer />
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
