import React, { useEffect, useState } from "react";
import Admin from "./Admin";
import AdminLogin from "./AdminLogin";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

function AdminDashboard() {
  const {adminLogin}=useSelector((state)=>state.adminLogin)
  const adminId = Cookies.get("admin");
  useEffect(() => {
    setLogin(adminLogin);
  }, [adminLogin]);
  const [login, setLogin] = useState(false);
  return <div>{login ? <Admin /> : <AdminLogin />}</div>;
}

export default AdminDashboard;
